import router from "@/router";
import {RepositoryFactory} from "@/RepositoryFactory";
import i18n from "@/i18n";
import {httpClient} from "@/plugins/axios";
const authService = RepositoryFactory.get('auth')

const state = () => ({
    user: {},
    authState: false,
    office:null,
    permissions:[],
})

const getters = {
    getUser :(state) => state.user,
    getOffice :(state) => state.office,
    getPermissions :(state) => state.permissions,
    isAuthenticated :(state) => state.authState,
}

const mutations = {
    SET_USER(state,user){
        state.user = user
    },
    SET_AUTH_STATE(state,authState){
        state.authState = authState
    },
    SET_OFFICE(state,office){
        state.office = office
    },
    SET_PERMISSIONS(state,permissions){
        state.permissions = permissions
    }
}

const actions = {
    async logout({commit}){
        try {
            await authService.logout();
        }catch (err){}
        localStorage.removeItem('accessToken')
        commit('SET_USER',{})
        commit('SET_AUTH_STATE',false)
        await router.push('/login')
    },
    async syncAuth({commit}){
        try {
            const {data} = await authService.me()
            commit('SET_USER',data.user)
            commit('SET_OFFICE',data.office)
            commit('SET_PERMISSIONS',data.permissions)
            commit('SET_AUTH_STATE',true)
        }catch (err) {
            commit('SET_USER',{})
            commit('SET_OFFICE',null)
            commit('SET_PERMISSIONS',[])
            commit('SET_AUTH_STATE',false)
            localStorage.removeItem('accessToken')
        }
    },

    async findOffice({commit}){
        const {data} = await authService.myOffice();
        commit('SET_OFFICE',data.office)
        return data;
    },

    async updateOffice({commit}){
        const {data} = await authService.updateOffice();
        commit('SET_OFFICE',data.office);
        return data;
    },

    async updateProfile({dispatch},payload){
        const {data} = await authService.updateProfile(payload)
        dispatch('syncAuth')
        return data;
    },

    async updateLanguage({dispatch},payload){
        const {data} = await authService.updateLanguage(payload)

        let locale = payload.language
        i18n.locale = locale
        localStorage.setItem('locale', locale)
        httpClient.defaults.headers.common['X_CONTENT_LANGUAGE'] = locale

        dispatch('syncAuth')
        return data;
    },

    async updatePassword({},payload){
        return await authService.updatePassword(payload)
    },

    async addImage({dispatch},image){
        const {data} = await authService.uploadImage(image)
        dispatch('syncAuth')
        return data;
    },

    async deleteImage({dispatch}){
        const {data} = await authService.deleteImage()
        dispatch('syncAuth')
        return data;
    },

    async forgotPassword({dispatch},email){
        return  await authService.sendForgotPasswordEmail(email)
    },

    async checkToken({},payload){
        if (!payload.token || !payload.email)
        {
            throw new Error('Invalid token or email! pleas try again or contact support')
        }

        return  await authService.checkResetPasswordToken(payload.token,payload.email)
    },

    async resetPassword({dispatch},payload){
        return await authService.resetPassword(payload)
    },

    async updateDoctorMetadata({dispatch},payload){
        const {data} = await authService.updateDoctorMetadata(payload)
        dispatch('syncAuth')
        return data;
    }

}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}