import BaseRepository from "@/RepositoryFactory/repositories/baseRepository";

export default class PatientNoteRepository extends BaseRepository{
    constructor() {
        super('patients');
    }

    /**
     * @param patientId
     * @param query
     * @returns {Promise<*>}
     */
    async find(patientId,query = {})
    {
        this.API_ROUTE = `/patients/${patientId}/notes`
        return super.find(query);
    }

    async findOneById(patientId,id) {
        this.API_ROUTE = `/patients/${patientId}/notes`
        return super.findOneById(id);
    }

    async create(patientId,payload) {
        this.API_ROUTE = `/patients/${patientId}/notes`
        return super.create(payload);
    }

    async update(patientId,id, payload) {
        this.API_ROUTE = `/patients/${patientId}/notes`
        return super.update(id, payload);
    }

    async delete(patientId,id) {
        this.API_ROUTE = `/patients/${patientId}/notes`
        return super.delete(id);
    }
}