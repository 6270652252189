import auth from "@/middlewares/auth";
import store from "@/store";
import ErrorService from "@/Exceptions/ErrorService";
import {can} from "@/middlewares";

export default [
    {
        path: '/medical-documents',
        name: 'medical-documents-index',
        meta:{
            middleware:[auth,can],
            permissions:['view-list-prescription', "view-list-justification"]
        },
        component: () => import(/* webpackChunkName: "medical-documents" */ '@/views/medical-documents/Index.vue'),
    },
    {
        path: '/medical-documents/prescriptions/create',
        name: 'medical-documents-prescriptions-create',
        meta:{
            middleware:[auth,can],
            permissions:['create-prescription']
        },
        component: () => import(/* webpackChunkName: "medical-document-prescriptions-create" */ '@/views/medical-documents/prescriptions/Create.vue')
    },
    {
        path: '/medical-documents/:id/prescriptions',
        name: 'medical-documents-prescriptions-show',
        beforeEnter: async (to, from, next) => {
            try {
                const {document} = await store.dispatch('medicalDocuments/findDocument',to.params.id)
                if (!document || document.type !=='prescription')
                {
                    next({name:'not-found'})
                }
                next()
            }catch (err)
            {
                ErrorService.onError(err).redirect()
            }
        },
        meta:{
            middleware:[auth,can],
            permissions:['view-prescription']
        },
        component: () => import(/* webpackChunkName: "medical-document-prescriptions-show" */ '@/views/medical-documents/prescriptions/Show.vue')
    },
    // {
    //     path: '/medical-documents/work-interruptions/Create',
    //     name: 'medical-document-work-interruptions-create',
    //     meta:{
    //         middleware:[auth]
    //     },
    //     component: () => import(/* webpackChunkName: "medical-document-work-interruption-create" */ '@/views/medical-documents/WorkInterruptionCreate.vue')
    // },
    {
        path: '/medical-documents/justifications/create',
        name: 'medical-documents-justifications-create',
        meta:{
            middleware:[auth,can],
            permissions:['create-justification']
        },
        component: () => import(/* webpackChunkName: "medical-document-justifications-create" */ '@/views/medical-documents/justifications/Create.vue')
    },

    {
        path: '/medical-documents/:id/justifications',
        name: 'medical-documents-justifications-show',
        beforeEnter: async (to, from, next) => {
            try {
                const {document} = await store.dispatch('medicalDocuments/findDocument',to.params.id)
                if (!document || document.type !=='justification')
                {
                    next({name:'not-found'})
                }
                next()
            }catch (err)
            {
                ErrorService.onError(err).redirect()
            }
        },
        meta:{
            middleware:[auth,can],
            permissions:['view-justification']
        },
        component: () => import(/* webpackChunkName: "medical-document-justifications-create" */ '@/views/medical-documents/justifications/Show.vue')
    },
]
