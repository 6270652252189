import {RepositoryFactory} from "@/RepositoryFactory";
const allergieservice = RepositoryFactory.get('allergies')
const state = () => ({
    allergy: {},
    allergies: null,
})

// getters
const getters = {
    getAllergy(state) {
        return state.allergy
    },
    getAllergies(state) {
        return state.allergies
    },
    getAllergiesList (state){
        if (state.allergies === null)
        {
            return []
        }

        if (state.allergies?.data)
        {
            return  state.allergies?.data;
        }

        return state.allergies
    },
    getMeta(state){
        if (state.allergies === null || !state.allergies.meta )
        {
            return {}
        }

        return state.allergies.meta
    },
}

// mutations
const mutations = {
    SET_ALLERGY(state,allergy){
        state.allergy = allergy
    },
    SET_ALLERGIES(state,allergies){
        state.allergies = allergies
    }
}

// actions
const actions = {
    async fetchAllergies({commit},payload){
        const {data} = await allergieservice.find(payload.patient_id,payload.query)
        commit('SET_ALLERGIES',data)
    },

    async createAllergy({commit},payload){
        const {data} = await allergieservice.create(payload.patient_id,payload.data)
        commit('SET_ALLERGY',data.allergy)
        return data
    },

    async fetchAllergy({commit},payload){
        const {data} = await allergieservice.findOneById(payload.patient_id, payload.id)
        commit('SET_ALLERGY',data.allergy)
    },

    async deleteAllergy({},payload){
        return await allergieservice.delete(payload.patient_id, payload.id)
    },

    async updateAllergy({commit},payload) {
        const {data} = await allergieservice.update(payload.patient_id, payload.id, payload.data)
        commit('SET_ALLERGY',data.allergy)
        return data
    }

}

export default  {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}