import auth from "@/middlewares/auth";
import can from "@/middlewares/can";
import store from "@/store";
import ErrorService from "@/Exceptions/ErrorService";
export default [
    {
        path: '/patients',
        name: 'patients-list',
        meta:{
            permissions:['view-list-patient'],
            middleware: [
                auth, can
            ]
        },
        component: () => import(/* webpackChunkName: "patients" */ '@/views/patients/Index.vue'),
        children: [
            {
                path: 'create',
                component: () => import(/* webpackChunkName: "patients-create" */ '@/components/modals/patients/PatientCreate.vue'),
                meta:{
                    permissions:['create-patient'],
                    middleware: [
                        auth, can
                    ]
                },
            },
        ]
    },
    {
        path: '/patients/:id',
        name: 'PatientShow',
        beforeEnter: async (to, from, next) => {
            try {
                await store.dispatch('patient/fetchPatient',to.params.id)
                next()
            }catch (err)
            {
                ErrorService.onError(err).redirect()
            }
        },
        meta:{
            permissions:['view-patient'],
            middleware: [
                auth, can
            ]
        },
        component: () => import(/* webpackChunkName: "patients-show" */ '@/views/patients/Show.vue'),
        children: [
            {
                path: 'schedule',
                name: 'patients-schedule-appointment',
                component: () => import(/* webpackChunkName: "patients-show" */ '@/views/appointments/Create.vue')
            },
            {
                path: 'notes',
                component: () => import(/* webpackChunkName: "patients-show" */ '@/components/modals/patients/PatientNotes.vue')
            },
            {
                path: 'files',
                component: () => import(/* webpackChunkName: "patients-show" */ '@/components/modals/patients/FilesList.vue')
            },
            {
                path: 'files/upload',
                component: () => import(/* webpackChunkName: "patients-show" */ '@/components/modals/patients/FileUpload.vue')
            }
        ]
    },
    {
        path: '/patients/:id/information',
        name: 'patient-information',
        beforeEnter: async (to, from, next) => {
            try {
                await store.dispatch('patient/fetchPatient',to.params.id)
                next()
            }catch (err)
            {
                ErrorService.onError(err).redirect()
            }
        },
        component: () => import(/* webpackChunkName: "patients-show" */ '@/views/patients/Information.vue')
    },
    {
        path: '/patients/:id/edit',
        name: 'patient-edit',
        beforeEnter: async (to, from, next) => {
            try {
                await store.dispatch('patient/fetchPatient',to.params.id)
                next()
            }catch (err)
            {
                ErrorService.onError(err).redirect()
            }
        },
        component: () => import(/* webpackChunkName: "patients-edit" */ '@/views/patients/Edit.vue'),
        children: [
            {
                name: 'medications-create',
                path: 'medications/create',
                component: () => import(/* webpackChunkName: "patients-edit" */ '@/components/modals/patients/MedicationsCreate.vue'),
            },
        ]
    },
    {
        path: '/patients/:id/folder',
        name: 'patient-folder',
        beforeEnter: async (to, from, next) => {
            try {
                await store.dispatch('patient/fetchPatient', to.params.id)
                next()
            }
            catch (err)
            {
                ErrorService.onError(err).redirect()
            }
        },
        component: () => import(/* webpackChunkName: "patients-folder" */ '@/views/patients/folder/Index.vue'),
        children: [
            {
                name: 'patient-folder-profile',
                path: 'profile',
                meta: {
                    permissions: ['patients'],
                    middleware: [
                        auth
                    ]
                },
                component: () => import(/* webpackChunkName: "patients-folder" */ '@/views/patients/folder/general/Profile.vue'),
            },
            {
                name: 'patient-folder-relatives',
                path: 'relatives',
                meta: {
                    permissions: ['patients'],
                    middleware: [
                        auth
                    ]
                },
                component: () => import(/* webpackChunkName: "patients-folder" */ '@/views/patients/folder/general/relatives/Index.vue'),
                children: [
                    {
                        name: 'patient-folder-relatives-create',
                        path: 'create',
                        meta: {
                            permissions: ['patients'],
                            middleware: [
                                auth
                            ]
                        },
                        component: () => import(/* webpackChunkName: "patients-folder" */ '@/views/patients/folder/general/relatives/Create.vue'),
                    },
                    {
                        name: 'patient-folder-relatives-edit',
                        path: ':relativeId/edit',
                        beforeEnter: async (to, from, next) => {
                            try {
                                await store.dispatch('relative/fetchRelative', {patient_id: to.params.id, id: to.params.relativeId})
                                next()
                            }catch (err)
                            {
                                ErrorService.onError(err).redirect()
                            }
                        },
                        meta: {
                            permissions: ['patients'],
                            middleware: [
                                auth
                            ]
                        },
                        component: () => import(/* webpackChunkName: "patients-folder" */ '@/views/patients/folder/general/relatives/Edit.vue'),
                    },
                ]
            },
            {
                name: 'patient-folder-general-information',
                path: 'general-information',
                beforeEnter: async (to, from, next) => {
                    try {
                        await store.dispatch('profile/fetchProfile', to.params.id)
                        next()
                    }catch (err)
                    {
                        console.error(err)
                        // ErrorService.onError(err).redirect()
                    }
                },
                meta:{
                    permissions: ['patients'],
                    middleware: [
                        auth
                    ]
                },
                component: () => import(/* webpackChunkName: "patients-folder" */ '@/views/patients/folder/medical/General.vue'),
            },
            {
                name: 'patient-folder-chronic-diseases',
                path: 'chronic-diseases',
                meta:{
                    permissions: ['patients'],
                    middleware: [
                        auth
                    ]
                },
                component: () => import(/* webpackChunkName: "patients-folder" */ '@/views/patients/folder/medical/chronic-diseases/Index.vue'),
                children: [
                    {
                        name: 'patient-folder-chronic-diseases-create',
                        path: 'create',
                        meta: {
                            permissions: ['patients'],
                            middleware: [
                                auth
                            ]
                        },
                        component: () => import(/* webpackChunkName: "patients-folder" */ '@/views/patients/folder/medical/chronic-diseases/Create.vue'),
                    },
                    {
                        name: 'patient-folder-chronic-diseases-edit',
                        path: ':chronicDiseaseId/edit',
                        beforeEnter: async (to, from, next) => {
                            try {
                                await store.dispatch('chronicDiseases/fetchChronicDisease', {patient_id: to.params.id, id: to.params.chronicDiseaseId})
                                next()
                            }catch (err)
                            {
                                ErrorService.onError(err).redirect()
                            }
                        },
                        meta: {
                            permissions: ['patients'],
                            middleware: [
                                auth
                            ]
                        },
                        component: () => import(/* webpackChunkName: "patients-folder" */ '@/views/patients/folder/medical/chronic-diseases/Edit.vue'),
                    },
                ]
            },
            {
                name: 'patient-folder-followed-treatments',
                path: 'followed-treatments',
                meta: {
                    permissions: ['patients'],
                    middleware: [
                        auth
                    ]
                },
                component: () => import(/* webpackChunkName: "patients-folder" */ '@/views/patients/folder/medical/followed-treatments/Index.vue'),
                children: [
                    {
                        name: 'patient-folder-followed-treatments-create',
                        path: 'create',
                        meta: {
                            permissions: ['patients'],
                            middleware: [
                                auth
                            ]
                        },
                        component: () => import(/* webpackChunkName: "patients-folder" */ '@/views/patients/folder/medical/followed-treatments/Create.vue'),
                    },
                    {
                        name: 'patient-folder-followed-treatments-edit',
                        path: ':medicationId/edit',
                        beforeEnter: async (to, from, next) => {
                            try {
                                await store.dispatch('medications/fetchMedication', {patient_id: to.params.id, id: to.params.medicationId})
                                next()
                            }catch (err)
                            {
                                ErrorService.onError(err).redirect()
                            }
                        },
                        meta: {
                            permissions: ['patients'],
                            middleware: [
                                auth
                            ]
                        },
                        component: () => import(/* webpackChunkName: "patients-folder" */ '@/views/patients/folder/medical/followed-treatments/Edit.vue'),
                    },
                ]
            },
            {
                name: 'patient-folder-hospitalizations',
                path: 'hospitalizations',
                meta: {
                    permissions: ['patients'],
                    middleware: [
                        auth
                    ]
                },
                component: () => import(/* webpackChunkName: "patients-folder" */ '@/views/patients/folder/medical/surgeries/Index.vue'),
                children: [
                    {
                        name: 'patient-folder-hospitalizations-create',
                        path: 'create',
                        meta: {
                            permissions: ['patients'],
                            middleware: [
                                auth
                            ]
                        },
                        component: () => import(/* webpackChunkName: "patients-folder" */ '@/views/patients/folder/medical/surgeries/Create.vue'),
                    },
                    {
                        name: 'patient-folder-hospitalizations-edit',
                        path: ':hospitalizationId/edit',
                        beforeEnter: async (to, from, next) => {
                            try {
                                await store.dispatch('hospitalizations/fetchHospitalization', {patient_id: to.params.id, id: to.params.hospitalizationId})
                                next()
                            }catch (err)
                            {
                                ErrorService.onError(err).redirect()
                            }
                        },
                        meta: {
                            permissions: ['patients'],
                            middleware: [
                                auth
                            ]
                        },
                        component: () => import(/* webpackChunkName: "patients-folder" */ '@/views/patients/folder/medical/surgeries/Edit.vue'),
                    },
                ]
            },
            {
                name: 'patient-folder-allergies',
                path: 'allergies',
                meta: {
                    permissions: ['patients'],
                    middleware: [
                        auth
                    ]
                },
                component: () => import(/* webpackChunkName: "patients-folder" */ '@/views/patients/folder/medical/allergies/Index.vue'),
                children: [
                    {
                        name: 'patient-folder-allergies-create',
                        path: 'create',
                        meta: {
                            permissions: ['patients'],
                            middleware: [
                                auth
                            ]
                        },
                        component: () => import(/* webpackChunkName: "patients-folder" */ '@/views/patients/folder/medical/allergies/Create.vue'),
                    },
                    {
                        name: 'patient-folder-allergies-edit',
                        path: ':allergyId/edit',
                        beforeEnter: async (to, from, next) => {
                            try {
                                await store.dispatch('allergies/fetchAllergy', {patient_id: to.params.id, id: to.params.allergyId})
                                next()
                            }catch (err)
                            {
                                ErrorService.onError(err).redirect()
                            }
                        },
                        meta: {
                            permissions: ['patients'],
                            middleware: [
                                auth
                            ]
                        },
                        component: () => import(/* webpackChunkName: "patients-folder" */ '@/views/patients/folder/medical/allergies/Edit.vue'),
                    },
                ]
            },
            {
                name: 'patient-folder-notes',
                path: 'notes',
                meta: {
                    permissions: ['patients'],
                    middleware: [
                        auth
                    ]
                },
                component: () => import(/* webpackChunkName: "patients-folder" */ '@/views/patients/folder/other/notes/Index.vue'),
                children: [
                    {
                        name: 'patient-folder-notes-create',
                        path: 'create',
                        meta: {
                            permissions: ['patients'],
                            middleware: [
                                auth
                            ]
                        },
                        component: () => import(/* webpackChunkName: "patients-folder" */ '@/views/patients/folder/other/notes/Create.vue'),
                    },
                    {
                        name: 'patient-folder-notes-edit',
                        path: ':noteId/edit',
                        beforeEnter: async (to, from, next) => {
                            try {
                                await store.dispatch('patientNote/fetchNote', { patient_id: to.params.id, id: to.params.noteId })
                                next()
                            }catch (err)
                            {
                                ErrorService.onError(err).redirect()
                            }
                        },
                        meta: {
                            permissions: ['patients'],
                            middleware: [
                                auth
                            ]
                        },
                        component: () => import(/* webpackChunkName: "patients-folder" */ '@/views/patients/folder/other/notes/Edit.vue'),
                    },
                ]
            },
            {
                name: 'patient-folder-files-and-documents',
                path: 'files-and-documents',
                meta: {
                    permissions: ['patients'],
                    middleware: [
                        auth
                    ]
                },
                component: () => import(/* webpackChunkName: "patients-folder" */ '@/views/patients/folder/other/files-and-documents/Index.vue'),
            },
            {
                name: 'patient-folder-medical-documents',
                path: 'medical-documents',
                meta: {
                    permissions: ['patients'],
                    middleware: [
                        auth
                    ]
                },
                component: () => import(/* webpackChunkName: "patients-folder" */ '@/views/patients/folder/other/medical-documents/Index.vue'),
            },
        ]
    },
    {
        name: 'patient-folder-medical-documents-prescriptions-create',
        path: '/patients/:id/folder/medical-documents/prescriptions/create',
        beforeEnter: async (to, from, next) => {
            try {
                await store.dispatch('patient/fetchPatient', to.params.id)
                next()
            }catch (err)
            {
                ErrorService.onError(err).redirect()
            }
        },
        meta: {
            permissions: ['patients'],
            middleware: [
                auth
            ]
        },
        component: () => import(/* webpackChunkName: "patients-folder" */ '@/views/medical-documents/prescriptions/Create.vue'),
    },

    {
        name: 'patient-folder-medical-documents-prescriptions-show',
        path: '/patients/:id/folder/medical-documents/prescriptions/:medicalDocumentId/prescription',
        beforeEnter: async (to, from, next) => {
            try {
                const {document} = await store.dispatch('medicalDocuments/findDocument',to.params.medicalDocumentId)
                if (!document || document.type !=='prescription' || parseInt(document.patient_id) !== parseInt(to.params.id))
                {
                    next({name:'not-found'})
                }
                next()
            }catch (err)
            {
                ErrorService.onError(err).redirect()
            }
        },
        meta: {
            permissions: ['patients'],
            middleware: [
                auth
            ]
        },
        component: () => import(/* webpackChunkName: "patients-folder" */ '@/views/medical-documents/prescriptions/Show.vue'),
    },
    {
        name: 'patient-folder-medical-documents-justifications-create',
        path: '/patients/:id/folder/medical-documents/justifications/create',
        beforeEnter: async (to, from, next) => {
            try {
                await store.dispatch('patient/fetchPatient', to.params.id)
                next()
            }catch (err)
            {
                ErrorService.onError(err).redirect()
            }
        },
        meta: {
            permissions: ['patients'],
            middleware: [
                auth
            ]
        },
        component: () => import(/* webpackChunkName: "patients-folder" */ '@/views/medical-documents/justifications/Create.vue'),
    },

    {
        name: 'patient-folder-medical-documents-justifications-show',
        path: '/patients/:id/folder/medical-documents/justifications/:medicalDocumentId/justification',
        beforeEnter: async (to, from, next) => {
            try {
                const {document} = await store.dispatch('medicalDocuments/findDocument',to.params.medicalDocumentId)
                if (!document || document.type !=='justification' || parseInt(document.patient_id) !== parseInt(to.params.id))
                {
                    next({name:'not-found'})
                }
                next()
            }catch (err)
            {
                ErrorService.onError(err).redirect()
            }
        },
        meta: {
            permissions: ['patients'],
            middleware: [
                auth
            ]
        },
        component: () => import(/* webpackChunkName: "patients-folder" */ '@/views/medical-documents/justifications/Show.vue'),
    },

]
