import BaseRepository from "@/RepositoryFactory/repositories/baseRepository";
import {httpClient} from "@/plugins/axios";

export default class AppointmentsRepository extends BaseRepository {
    constructor() {
        super('appointments');
    }

    async availableSlots(payload){
        return await httpClient.post(`${this.API_ROUTE}/available-time-slots`,payload)
    }
}