import BaseRepository from "@/RepositoryFactory/repositories/baseRepository";
import {httpClient} from "@/plugins/axios";

export default class PaymentsRepository extends BaseRepository {
    constructor() {
        super('payments');
    }

    async getStates(query = {}){
        return await httpClient.get(this.API_ROUTE+'/states',{params:query})
    }

    async updateState(id,payload){
        return await httpClient.put(`${this.API_ROUTE}/${id}/state`,payload)
    }

}