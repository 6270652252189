import {RepositoryFactory} from "@/RepositoryFactory";
const noteService = RepositoryFactory.get('patientNote')
const state = () => ({
    note: {},
    notes: null,
})

// getters
const getters = {
    getNote(state) {
        return state.note
    },
    getNotes(state) {
        return state.notes
    },
    getNotesList (state){
        if (state.notes === null)
        {
            return []
        }

        if (state.notes?.data)
        {
            return  state.notes?.data;
        }

        return state.notes
    },
    getMeta(state){
        if (state.notes === null || !state.notes.meta )
        {
            return {}
        }

        return state.notes.meta
    },
}

// mutations
const mutations = {
    SET_NOTE(state,note){
        state.note = note
    },
    SET_NOTES(state,notes){
        state.notes = notes
    }
}

// actions
const actions = {
    async fetchNotes({commit},payload){
        const {data} = await noteService.find(payload.patient_id,payload.query)
        commit('SET_NOTES',data)
    },

    async createNote({commit},payload){
        const {data} = await noteService.create(payload.patient_id,payload.data)
        commit('SET_NOTE',data.note)
        return data
    },

    async fetchNote({commit},payload){
        const {data} = await noteService.findOneById(payload.patient_id, payload.id)
        commit('SET_NOTE',data.note)
    },

    async deleteNote({},payload){
        return await noteService.delete(payload.patient_id, payload.id)
    },

    async updateNote({commit},payload) {
        const {data} = await noteService.update(payload.patient_id, payload.id, payload.data)
        commit('SET_NOTE',data.note)
        return data
    }

}

export default  {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}