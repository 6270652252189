import {RepositoryFactory} from "@/RepositoryFactory";
const justificationService = RepositoryFactory.get('justifications')
const state = () => ({

    justification: {},
    justifications: null,
})

// getters
const getters = {
    getJustification(state) {
        return state.justification
    },
    getJustifications(state) {
        return state.justifications
    },
    getJustificationsList (state){
        if (state.justifications === null)
        {
            return []
        }

        if (state.justifications?.data)
        {
            return  state.justifications?.data;
        }

        return state.justifications
    },
    getMeta(state){
        if (state.justifications === null || !state.justifications.meta )
        {
            return {}
        }

        return state.justifications.meta
    },
}

// mutations
const mutations = {
    SET_JUSTIFICATION(state,justification){
        state.justification = justification
    },

    SET_JUSTIFICATIONS(state,justifications){
        state.justifications = justifications
    },

}

// actions
const actions = {
    async fetchJustifications({commit,state},query = null){
        let q = {
            ...query
        }
        const {data} = await justificationService.find(q)
        commit('SET_JUSTIFICATIONS',data)
    },

    async createJustification({commit},payload){
        const {data} = await justificationService.create(payload.patient_id,payload.data)
        commit('SET_JUSTIFICATION',data.justification)
        return data;
    },

    async fetchJustification({commit},id){
        const {data} = await justificationService.findOneById(id)
        commit('SET_JUSTIFICATION',data.justification)
        return data
    },

    async deleteJustification({},id){
        return await justificationService.delete(id)
    },

    async updateJustification({commit},payload) {
        const {data} = await justificationService.update(payload.patient_id,payload.id,payload.data)
        commit('SET_JUSTIFICATION',data.justification)
        return data;
    },
}

export default  {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}