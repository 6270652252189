
const defaults = {
    type:'success',
    title:'',
    text:'',
    timeout:process.env.VUE_APP_ALERT_TIMEOUT ?? 5000
}

const state = () => ({
    text: null,
    type: null,
    title: null,
    show: false,
    timeout: null,
})

// getters
const getters = {
    text :(state) => state.text,
    title :(state) => state.title,
    type :(state) => state.type,
    isShown :(state) => state.show,
    timeout :(state) => state.timeout,
}



// mutations
const mutations = {
    SET_ALERT_TEXT(state,text) {
        state.text = text
    },
    SET_ALERT_TITLE(state,title) {
        state.title = title
    },
    SET_ALERT_TYPE(state,type) {
        state.type = type
    },
    SET_ALERT_TIMEOUT(state,time) {
        state.timeout = time
    },
    SET_SHOW(state,value) {
        state.show = value
    },
}

// actions
const actions = {
    showAlert({commit,state},options){
        options = {...defaults,...options}
        commit('SET_ALERT_TYPE',options.type)
        commit('SET_ALERT_TIMEOUT',options.timeout)
        commit('SET_ALERT_TEXT',options.text)
        commit('SET_ALERT_TITLE',options.title)
        commit('SET_SHOW',true)

        const handler = setTimeout(() => {
            commit('SET_SHOW',false)
            commit('SET_ALERT_TYPE',null)
            commit('SET_ALERT_TEXT',null)
            commit('SET_ALERT_TITLE',null)
            commit('SET_ALERT_TIMEOUT',null)
            clearTimeout(handler);
        },options.timeout)
    },

    clearAlert({commit}){
        commit('SET_SHOW',false)
        commit('SET_ALERT_TYPE',null)
        commit('SET_ALERT_TEXT',null)
        commit('SET_ALERT_TITLE',null)
        commit('SET_ALERT_TIMEOUT',null)
    }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}




