import {notify,success,error} from '@/Helpers/AlertService'

const alertMessage = {
    install(Vue, options) {
        Vue.prototype.success = success;
        Vue.prototype.notify = notify;
        Vue.prototype.error = error;
    },
}

export default alertMessage;