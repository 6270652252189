import BaseRepository from "@/RepositoryFactory/repositories/baseRepository";
import {httpClient} from "@/plugins/axios";

export default class VisitsRepository extends BaseRepository {
    constructor() {
        super('visits');
    }

    async getStates(query = {}){
        return await httpClient.get(this.API_ROUTE+'/states',{params:query})
    }

    async updateState(id,payload){
        return await httpClient.put(`${this.API_ROUTE}/${id}/state`,payload)
    }

    async getVisitsCount(query = {}){
        return await httpClient.get(this.API_ROUTE+'/count',{params:query})
    }

    async getQueueData(query = {})
    {
        return await httpClient.get(this.API_ROUTE+'/queue',{params:query})

    }

}