import Vue from "vue";
import {extend, ValidationObserver, ValidationProvider, setInteractionMode, localize, configure} from 'vee-validate'
import * as rules from 'vee-validate/dist/rules';
import {camelCase} from "lodash";

Object.keys(rules).forEach(rule => {
    extend(rule, {
        ...rules[rule], // copies rule configuration
        // message: messages[rule] // assign message
    });
});
import i18n from "../i18n";

configure({
    // Use custom default message handler.
    defaultMessage: (field, values) => {
        if (field === 'first_name' )
        {
            field = 'firstname'
        }

        if (field === 'last_name' )
        {
            field = 'lastname'
        }
        if (field === 'birth_date' )
        {
            field = 'birthdate'
        }

        values._field_ = i18n.t(`labels.${field}`);
        console.log(values._field_)
        return i18n.t(`validation.${values._rule_}`, values);
    }
});

Vue.component('ValidationObserver',ValidationObserver)
Vue.component('ValidationProvider',ValidationProvider)

setInteractionMode('eager')
