import BaseRepository from "@/RepositoryFactory/repositories/baseRepository";
import {httpClient} from "@/plugins/axios";

export default class UsersRepository extends BaseRepository {
    constructor() {
        super('users');
    }

    async findDoctors(query = {}){
        return await httpClient.get(`${this.API_ROUTE}/doctors`,{params:query})
    }

}