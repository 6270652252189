



// year?: 'numeric' | '2-digit',
// month?: 'numeric' | '2-digit' | 'narrow' | 'short' | 'long',
// day?: 'numeric' | '2-digit',
// hour?: 'numeric' | '2-digit',
// minute?: 'numeric' | '2-digit',
// second?: 'numeric' | '2-digit',
// weekday?: 'narrow' | 'short' | 'long',
// hour12?: boolean,
// era?: 'narrow' | 'short' | 'long',
// timeZone?: string, // IANA time zone
// timeZoneName?: 'short' | 'long',
// localeMatcher?: 'lookup' | 'best fit',
// formatMatcher?: 'basic' | 'best fit'
export const dateTimeFormats = {
    'en': {
        short:{
            year: 'numeric', // 'numeric' | '2-digit',
            month: 'short', // 'numeric' | '2-digit' | 'narrow' | 'short' | 'long',
            day: '2-digit', // 'numeric' | '2-digit',
        },
        long:{
            year: 'numeric', // 'numeric' | '2-digit',
            month: 'long', // 'numeric' | '2-digit' | 'narrow' | 'short' | 'long',
            day: 'numeric', // 'numeric' | '2-digit',
            hour: 'numeric', // 'numeric' | '2-digit',
            minute: 'numeric' ,//'numeric' | '2-digit',
            second: 'numeric',//'numeric' | '2-digit',
            weekday: 'long',//'narrow' | 'short' | 'long',
            hour12: false //boolean,
        },
    },
    'fr': {
        short:{
            year: '2-digit', // 'numeric' | '2-digit',
            month: 'short', // 'numeric' | '2-digit' | 'narrow' | 'short' | 'long',
            day: '2-digit', // 'numeric' | '2-digit',
        },
        long:{
            year: 'numeric', // 'numeric' | '2-digit',
            month: 'long', // 'numeric' | '2-digit' | 'narrow' | 'short' | 'long',
            day: 'numeric', // 'numeric' | '2-digit',
            hour: 'numeric', // 'numeric' | '2-digit',
            minute: 'numeric' ,//'numeric' | '2-digit',
            second: 'numeric',//'numeric' | '2-digit',
            weekday: 'long',//'narrow' | 'short' | 'long',
            hour12: false //boolean,
        },
    }
}


// style?: 'decimal' | 'currency' | 'percent',
// currency?: string, // ISO 4217 currency codes
// currencyDisplay?: 'symbol' | 'code' | 'name',
// useGrouping?: boolean,
// minimumIntegerDigits?: number,
// minimumFractionDigits?: number,
// maximumFractionDigits?: number,
// minimumSignificantDigits?: number,
// maximumSignificantDigits?: number,
// localeMatcher?: 'lookup' | 'best fit',
// formatMatcher?: 'basic' | 'best fit'
export const numberFormats = {
    en:{
        currency: {
            style:'currency', currency: 'DZD' , currencyDisplay : 'symbol'
        },
    },
    fr: {
        currency: {
            style:'currency', currency: 'DZD' , currencyDisplay : 'name'
        }
    }
}