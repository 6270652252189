import {RepositoryFactory} from "@/RepositoryFactory";
const drugService = RepositoryFactory.get('drugs')

const state = () => ({
    drug: {},
    drugs: null,
})

const getters = {
    getDrug :(state) => state.drug,
    getDrugs :(state) => state.drugs,

    getDrugList :(state) => {
        if (state.drugs === null)
        {
            return []
        }

        if (state.drugs?.data)
        {
            return  state.drugs?.data;
        }

        return state.drugs
    },
    getMeta(state){
        if (state.drugs === null || !state.drugs.meta )
        {
            return {}
        }

        return state.drugs.meta
    },
}

const mutations = {
    SET_DRUG(state,drug){
        state.drug = drug
    },
    SET_DRUGS(state,drugs){
        state.drugs = drugs
    },

}

const actions = {
    async findDrugs({commit},query = null){
        const {data} = await drugService.find(query)
        commit('SET_DRUGS',data)
    },

    async create({commit},payload){
        const {data} = await drugService.create(payload)
        commit('SET_DRUG',data.drug)
        return data;
    },

    async findDrug({commit},id){
        const {data} = await drugService.findOneById(id)
        commit('SET_DRUG',data.drug)
    },

    async deleteDrug({},id){
        return await drugService.delete(id)
    },

    async updateDrug({commit},payload) {
        const {data} = await drugService.update(payload.id,payload.data)
        commit('SET_DRUG',data.drug)
        return data;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}