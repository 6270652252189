import auth from "@/middlewares/auth";
import store from "@/store";
import ErrorService from "@/Exceptions/ErrorService";
import can from "@/middlewares/can";


export default [
    {
        path: '/drugs',
        name: 'drugs',
        meta:{
            permissions:['view-list-drug'],
            middleware:[auth, can]
        },
        component: () => import(/* webpackChunkName: "drugs" */ '@/views/drugs/Index.vue'),
        children: [
            {
                meta:{
                    permissions:['create-drug'],
                    middleware:[auth, can]
                },
                path: 'create',
                name: 'drugs-create',
                component: () => import(/* webpackChunkName: "drugs" */ '@/views/drugs/Create.vue')
            },
            {
                meta:{
                    permissions:['edit-drug'],
                    middleware:[auth, can]
                },
                beforeEnter: async (to, from, next) => {
                    try {
                        await store.dispatch('drugs/findDrug',to.params.id)
                        next()
                    }catch (err)
                    {
                        ErrorService.onError(err).redirect()
                    }
                },
                path: ':id/edit',
                name: 'drugs-edit',
                component: () => import(/* webpackChunkName: "drugs" */ '@/views/drugs/Edit.vue')
            },
        ]
    },

]
