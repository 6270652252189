import {RepositoryFactory} from "@/RepositoryFactory";
const patientService = RepositoryFactory.get('patients')
const state = () => ({
    patient: {},
    patients: null,
})

// getters
const getters = {
    getPatient(state) {
        return state.patient
    },
    getPatients(state) {
        return state.patients
    },
    getPatientsList (state){
        if (state.patients === null)
        {
            return []
        }

        if (state.patients?.data)
        {
            return  state.patients?.data;
        }

        return state.patients
    },
    getMeta(state){
        if (state.patients === null || !state.patients.meta )
        {
            return {}
        }

        return state.patients.meta
    },
}

// mutations
const mutations = {
    SET_PATIENT(state,patient){
        state.patient = patient
    },
    SET_PATIENTS(state,patients){
        state.patients = patients
    }
}

// actions
const actions = {
    async fetchPatients({commit},query = null){
        const {data} = await patientService.find(query)
        commit('SET_PATIENTS',data)
    },

    async fetchPatient({commit},id){
        const {data} = await patientService.findOneById(id)
        commit('SET_PATIENT',data.patient)
    },

    async deletePatient({},id){
        return await patientService.delete(id)
    },

    async updatePatient({commit},payload) {
        const {data} = await patientService.update(payload.id,payload.data)
        commit('SET_PATIENT',data.patient)
        return data;
    }

}

export default  {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}