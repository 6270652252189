import {RepositoryFactory} from "@/RepositoryFactory";
const hospitalizationService = RepositoryFactory.get('hospitalizations')
const state = () => ({
    hospitalization: {},
    hospitalizations: null,
})

// getters
const getters = {
    getHospitalization(state) {
        return state.hospitalization
    },
    getHospitalizations(state) {
        return state.hospitalizations
    },
    getHospitalizationsList (state){
        if (state.hospitalizations === null)
        {
            return []
        }

        if (state.hospitalizations?.data)
        {
            return  state.hospitalizations?.data;
        }

        return state.hospitalizations
    },
    getMeta(state){
        if (state.hospitalizations === null || !state.hospitalizations.meta )
        {
            return {}
        }

        return state.hospitalizations.meta
    },
}

// mutations
const mutations = {
    SET_HOSPITALIZATION(state,hospitalization){
        state.hospitalization = hospitalization
    },
    SET_HOSPITALIZATIONS(state,hospitalizations){
        state.hospitalizations = hospitalizations
    }
}

// actions
const actions = {
    async fetchHospitalizations({commit},payload){
        const {data} = await hospitalizationService.find(payload.patient_id,payload.query)
        commit('SET_HOSPITALIZATIONS',data)
    },

    async createHospitalization({commit},payload){
        const {data} = await hospitalizationService.create(payload.patient_id,payload.data)
        commit('SET_HOSPITALIZATION',data.hospitalization)
        return data
    },

    async fetchHospitalization({commit},payload){
        const {data} = await hospitalizationService.findOneById(payload.patient_id, payload.id)
        commit('SET_HOSPITALIZATION',data.hospitalization)
    },

    async deleteHospitalization({},payload){
        return await hospitalizationService.delete(payload.patient_id, payload.id)
    },

    async updateHospitalization({commit},payload) {
        const {data} = await hospitalizationService.update(payload.patient_id, payload.id, payload.data)
        commit('SET_HOSPITALIZATION',data.hospitalization)
        return data
    }

}

export default  {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}