import BaseRepository from "@/RepositoryFactory/repositories/baseRepository";
import {httpClient} from "@/plugins/axios";

export default class ProfileRepository extends BaseRepository{
    constructor() {
        super('patients');
    }

    /**
     * @param patientId
     * @param query
     * @returns {Promise<*>}
     */
    async findOne(patientId,query = {})
    {
        this.API_ROUTE = `/patients/${patientId}/profile`
        return await httpClient.get(this.API_ROUTE,{params:query})
    }

    async update(patientId,id, payload) {
        this.API_ROUTE = `/patients/${patientId}/profile`
        return await httpClient.put(`${this.API_ROUTE}`,payload)
    }

}