import BaseRepository from "@/RepositoryFactory/repositories/baseRepository";
import {httpClient} from "@/plugins/axios";

export default class TreatmentsRepository extends BaseRepository {
    constructor() {
        super('treatments');
    }

    async updateStatus(id, payload) {
        return httpClient.put(`${this.API_ROUTE}/${id}/status`,payload);
    }

}