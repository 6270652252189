import Vue from 'vue'
import Vuex, {createLogger} from "vuex";

Vue.use(Vuex)

import auth from "@/store/modules/auth";
import office from "@/store/modules/office";
import patient from "@/store/modules/patient/patients";
import alert from "@/store/modules/utilities/alert";
import globalSettings from "@/store/modules/utilities/globalSettings";
import user from "@/store/modules/users";
import treatment from "@/store/modules/treatments";
import appointments from "@/store/modules/patient/appointments";
import visits from "@/store/modules/patient/visits";
import relative from "@/store/modules/patient/relatives";
import documents from "@/store/modules/patient/documents";
import drugs from "@/store/modules/drugs";
import payment from "@/store/modules/payments";
import profile from "@/store/modules/patient/profile";
import medications from "@/store/modules/patient/medications";
import hospitalizations from "@/store/modules/patient/hospitalizations";
import prescriptions from "@/store/modules/patient/medicalDocuments/prescriptions";
import allergies from "@/store/modules/patient/allergies";
import chronicDiseases from "@/store/modules/patient/chronicDiseases";
import medicalDocuments from "@/store/modules/medicalDocuments";
import justification from "@/store/modules/patient/medicalDocuments/justifications";
import roles from "@/store/modules/roles";
import patientNote from "@/store/modules/patient/patientNote";
const debug = true;
export default   new Vuex.Store({
    modules:{
        auth,
        office,
        globalSettings,
        patient,
        user,
        treatment,
        appointments,
        visits,
        payment,
        relative,
        documents,
        prescriptions,
        drugs,
        profile,
        medications,
        allergies,
        alert,
        hospitalizations,
        justification,
        chronicDiseases,
        medicalDocuments,
        patientNote,
        roles
    },
    strict: debug,
    plugins: debug ? [createLogger()] : []
})