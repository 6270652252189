<template>
  <v-container fluid>

    <v-row>

      <v-col cols="12" lg="7" v-if="can('view-list-patient')">
        <v-card elevation="0" class="fill-height" color="#152443" dark>
          <v-card-subtitle>
            <v-skeleton-loader :loading="loading" type="text">
              {{currentDateTime}}
            </v-skeleton-loader>
          </v-card-subtitle>
          <v-card-text>
            <v-skeleton-loader class="mb-4" :loading="loading" type="heading">
              <h1 class="text-capitalize font-weight-medium white--text mb-10">
                {{ $t('messages.static.goodMorning') }} <span class="font-weight-light">{{ authUser.name }}</span>
              </h1>
            </v-skeleton-loader>
            <div class="d-none d-md-block">
              <v-skeleton-loader class="fill-height" :loading="loading" type="image">
                <v-img src="@/assets/doctor.svg" height="210" contain></v-img>
              </v-skeleton-loader>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="12" lg="5">
        <v-row dense>

          <v-col cols="12" sm="6" md="12">
            <v-card elevation="0" class="h-100">
              <v-card-text>
                <v-skeleton-loader class="fill-height" :loading="loading" type="article">
                  <h4 class="text-uppercase black--text">
                    {{ $t('messages.static.upcomingAppointments') }}
                  </h4>
                  <div class="display-2 blue--text font-weight-regular mt-1">
                    {{ dashboard.upcoming_appointments_count }}
                  </div>
                  <div class="grey--text mt-2 text-capitalize">
                    {{ $t('messages.static.upcomingAppointments') }}
                  </div>
                  <v-btn class="blue--text text-capitalize mt-3" color="blue lighten-5" elevation="0" to="/appointments" small block dark>
                    {{ $t('actions.more') }}
                  </v-btn>
                </v-skeleton-loader>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" sm="6" md="12">
            <v-card :loading="loading" elevation="0" class="h-100">
              <v-card-text>
                <v-skeleton-loader class="fill-height" :loading="loading" type="article">
                  <h4 class="text-uppercase black--text">
                    {{ $t('messages.static.clinicInformation') }}
                  </h4>
                  <div class="mt-3">
                    <v-icon color="blue" small left>mdi-map-marker</v-icon>{{ dashboard.office?.street_address + ', ' + dashboard.office?.zip_code + ', ' + dashboard.office?.city + ', ' + dashboard.office?.country + '.' }}
                  </div>
                  <div v-if="dashboard.office?.phone_1 || dashboard.office?.phone_2" class="mt-2">
                    <v-icon color="blue" small left>mdi-phone</v-icon>{{ '00 (213)' + dashboard.office?.phone_1 }}<span class="mx-2">-</span><v-icon color="blue" small left>mdi-phone</v-icon>{{ '00 (213)' + dashboard.office?.phone_2 }}
                  </div>
                  <v-btn class="blue--text text-capitalize mt-3" color="blue lighten-5" elevation="0" to="/settings/company/information" small block dark>
                    {{ $t('actions.edit') }}
                  </v-btn>
                </v-skeleton-loader>
              </v-card-text>
            </v-card>
          </v-col>

        </v-row>
      </v-col>

    </v-row>

    <v-row>

      <v-col cols="12" md="6">
        <v-card :loading="loading" elevation="0" class="fill-height">

          <v-card-text>
            <v-skeleton-loader class="mb-4" :loading="loading" type="paragraph, image">
            <div  class="d-block d-sm-flex align-center mb-4">
              <h4 class="text-uppercase black--text">{{ $t('messages.static.todaysAppointments') }} ({{ todayAppointments.length }})</h4>
              <v-spacer></v-spacer>
              <v-btn class="text-capitalize" color="blue" elevation="0" @click="$router.push({ name: 'appointments-create' }).catch(()=>{})" small dark>
                {{ $t('actions.scheduleAnAppointment') }}
                <v-icon right>mdi-calendar-clock</v-icon>
              </v-btn>
            </div>
            <div v-if="todayAppointments.length === 0" class="py-6">
              <v-img src="@/assets/booking.svg" height="120" contain></v-img>
              <h3 class="text-capitalize font-weight-medium blue--text text-center mt-6">
                {{ $t('messages.static.noAppointmentComingUpToday') }}
              </h3>
            </div>
            <v-card v-for="appointment in todayAppointments" :key="appointment.id" class="blue lighten-5 mt-2 text-capitalize" elevation="0">
              <v-card-text class="d-flex justify-space-between align-center py-3">
                <div>
                  <div class="grey--text">treatment</div>
                  <div class="font-weight-medium black--text">{{ appointment.treatment?.name }}</div>
                </div>
                <div class="grey--text">
                  <v-icon small left>mdi-clock</v-icon>
                  {{  appointment.startTime }} - {{ appointment.endTime }}
                </div>
              </v-card-text>
            </v-card>
            <v-btn v-if="todayAppointments.length > 0" class="text-capitalize mt-4" color="blue" elevation="0" to="/appointments" text small block>
              see all
            </v-btn>
            </v-skeleton-loader>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6" md="3">
        <v-card :loading="loading"  class="fill-height" elevation="0">
          <v-card-text>
            <v-skeleton-loader class="fill-height" :loading="loading" type="article, divider, article">
              <h4 class="text-uppercase black--text mb-4">
                {{ $t('messages.static.totalPatientsThisMonth') }}
              </h4>
              <div class="display-2 blue--text font-weight-regular mt-1">{{ dashboard.patients_count_for_this_month }}</div>
              <div class="grey--text mt-2 text-capitalize">
                {{ $t('messages.static.totalPatientsThisMonth') }}
              </div>
              <v-divider class="my-5"></v-divider>
              <h4 class="text-uppercase black--text mb-4">
                {{ $t('messages.static.totalPatientsAllTime') }}
              </h4>
              <div class="display-2 blue--text font-weight-regular mt-1">{{ dashboard.patients_count }}</div>
              <div class="grey--text mt-2 text-capitalize">
                {{ $t('messages.static.totalPatientsAllTime') }}
              </div>
            </v-skeleton-loader>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6" md="3">
        <v-card class="fill-height" :loading="loading" elevation="0">
          <v-card-text>
            <v-skeleton-loader class="fill-height" :loading="loading" type="article, divider, article">
              <h4 class="text-uppercase black--text mb-4">
                {{ $t('messages.static.totalIncomeThisMonth') }}
              </h4>
              <div class="display-2 blue--text font-weight-regular mt-1">
                {{ dashboard.total_income_for_this_month }}
              </div>
              <div class="grey--text mt-2 text-capitalize">
                {{ $t('messages.static.totalIncomeThisMonth') }}
              </div>
              <v-divider class="my-5"></v-divider>
              <h4 class="text-uppercase black--text mb-4">
                {{ $t('messages.static.totalIncomeAllTime') }}
              </h4>
              <div class="display-2 blue--text font-weight-regular mt-1">
                {{ dashboard.total_income }}
                <v-skeleton-loader v-if="loading" :loading="loading" type="button"></v-skeleton-loader>
              </div>
              <div class="grey--text mt-2 text-capitalize">
                {{ $t('messages.static.totalIncomeAllTime') }}
              </div>
            </v-skeleton-loader>
          </v-card-text>
        </v-card>

      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import moment from "moment";
import {RepositoryFactory} from "@/RepositoryFactory";
const dashboardService = RepositoryFactory.get('dashboard')
export default {
  name: "DashboardView",
  data: () => ({
    interval: null,
    loading: false,
    currentDateTime: null,
    dashboard: {
      today_appointments: []
    }
  }),
  computed:{
    ...mapGetters({
      authUser:'auth/getUser'
    }),
    todayAppointments(){
      return this.dashboard.today_appointments.map(a => ({
        ...a,
        startTime:moment.utc(a.start_datetime).format('h:mm'),
        endTime:moment.utc(a.end_datetime).format('h:mm'),
      }))
    }
  },
  created() {
    this.interval = setInterval(() => {
      this.currentDateTime = moment().format('MMMM Do YYYY, h:mm:ss');
    }, 0);
  },
  mounted() {
    console.log('mounted')
    this.getDataFromApi();
  },
  methods:{
    getDataFromApi(){
      this.loading = true
      dashboardService.find()
          .then(({data}) => {
            this.dashboard = data.dashboard_data
          }).catch(err => {
        console.log(err)
      }).finally(() => this.loading = false)
    }
  },
  destroyed() {
    clearInterval(this.interval)
  }


}
</script>


