import store from "@/store";

const can = (key) => {
    if (Number.isInteger(parseInt(key)))
    {
        return store.getters['auth/getPermissions'].some(e => e.id === parseInt(key))
    }

    return store.getters['auth/getPermissions'].some(e => e.name === key)
};
const canNot = (key) => {
    return  !can(key)
};
const canAny = (keys) => {
    if (keys instanceof Array)
    {
        for(let i = 0;i < keys.length;i++)
        {
            if (can(keys[i])){
                return true;
            }
        }
        return false;
    }

    return  can(keys);
};

export  {
    can,canNot,canAny
}
