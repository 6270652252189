import guest from "@/middlewares/guest";
import store from "@/store";
import ErrorService from "@/Exceptions/ErrorService";

export default [
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ '@/views/authentication/Login.vue'),
        meta:{
            layout:'AuthLayout',
            middleware: [
                guest
            ]
        }
    },
    {
        path: '/forgot-password',
        name: 'Forgot',
        component: () => import(/* webpackChunkName: "forgot-password" */ '@/views/authentication/ForgotPassword.vue'),
        meta:{
            layout:'AuthLayout',
            middleware: [
                guest
            ]
        }
    },
    {
        path: '/password/reset/:token',
        name: 'reset-password',
        beforeEnter: async (to, from, next) => {
            try {
                await store.dispatch('auth/checkToken',{
                    token:to.params.token,
                    email:to.query.email,
                })
                next()
            }catch (err)
            {
                ErrorService.onError(err).redirect()
            }
        },
        component: () => import(/* webpackChunkName: "reset-password" */ '@/views/authentication/ResetPassword.vue'),
        meta:{
            layout:'AuthLayout',
            middleware: [
                guest
            ]
        }
    },
]