import {RepositoryFactory} from "@/RepositoryFactory";
const medicationService = RepositoryFactory.get('medications')
const state = () => ({
    medication: {},
    medications: null,
})

// getters
const getters = {
    getMedication(state) {
        return state.medication
    },
    getMedications(state) {
        return state.medications
    },
    getMedicationsList (state){
        if (state.medications === null)
        {
            return []
        }

        if (state.medications?.data)
        {
            return  state.medications?.data;
        }

        return state.medications
    },
    getMeta(state){
        if (state.medications === null || !state.medications.meta )
        {
            return {}
        }

        return state.medications.meta
    },
}

// mutations
const mutations = {
    SET_MEDICATION(state,medication){
        state.medication = medication
    },
    SET_MEDICATIONS(state,medications){
        state.medications = medications
    }
}

// actions
const actions = {
    async fetchMedications({commit},payload){
        const {data} = await medicationService.find(payload.patient_id,payload.query)
        commit('SET_MEDICATIONS',data)
    },

    async createMedication({commit},payload){
        const {data} = await medicationService.create(payload.patient_id,payload.data)
        commit('SET_MEDICATION',data.medication)
        return data
    },

    async fetchMedication({commit},payload){
        const {data} = await medicationService.findOneById(payload.patient_id, payload.id)
        commit('SET_MEDICATION',data.medication)
    },

    async deleteMedication({},payload){
        return await medicationService.delete(payload.patient_id, payload.id)
    },

    async updateMedication({commit},payload) {
        const {data} = await medicationService.update(payload.patient_id, payload.id, payload.data)
        commit('SET_MEDICATION',data.medication)
        return data
    }

}

export default  {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}