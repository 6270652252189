import BaseRepository from "@/RepositoryFactory/repositories/baseRepository";
import {httpClient} from "@/plugins/axios";

export default class DocumentsRepository extends BaseRepository{
    constructor() {
        super('patients');
    }

    /**
     * @param patientId
     * @param query
     * @returns {Promise<*>}
     */
    async find(patientId,query = {})
    {
        this.API_ROUTE = `/patients/${patientId}/documents`
        return super.find(query);
    }

    async findOneById(patientId,id) {
        this.API_ROUTE = `/patients/${patientId}/documents`
        return super.findOneById(id);
    }

    async create(patientId,payload) {
        this.API_ROUTE = `/patients/${patientId}/documents`
        return await httpClient.post(this.API_ROUTE,payload,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    async delete(patientId,id) {
        this.API_ROUTE = `/patients/${patientId}/documents`
        return super.delete(id);
    }
}