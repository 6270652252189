import {RepositoryFactory} from "@/RepositoryFactory";
const relativeService = RepositoryFactory.get('relatives')
const state = () => ({
    relative: {},
    relatives: null,
})

// getters
const getters = {
    getRelative(state) {
        return state.relative
    },
    getRelatives(state) {
        return state.relatives
    },
    getRelativesList (state){
        if (state.relatives === null)
        {
            return []
        }

        if (state.relatives?.data)
        {
            return  state.relatives?.data;
        }

        return state.relatives
    },
    getMeta(state){
        if (state.relatives === null || !state.relatives.meta )
        {
            return {}
        }

        return state.relatives.meta
    },
}

// mutations
const mutations = {
    SET_RELATIVE(state,relative){
        state.relative = relative
    },
    SET_RELATIVES(state,relatives){
        state.relatives = relatives
    }
}

// actions
const actions = {
    async fetchRelatives({commit},payload){
        const {data} = await relativeService.find(payload.patient_id,payload.query)
        commit('SET_RELATIVES',data)
    },

    async createRelative({commit},payload){
        const {data} = await relativeService.create(payload.patient_id,payload.data)
        commit('SET_RELATIVE',data.relative)
        return data
    },

    async fetchRelative({commit},payload){
        const {data} = await relativeService.findOneById(payload.patient_id, payload.id)
        commit('SET_RELATIVE',data.relative)
    },

    async deleteRelative({},payload){
        return await relativeService.delete(payload.patient_id, payload.id)
    },

    async updateRelative({commit},payload) {
        const {data} = await relativeService.update(payload.patient_id, payload.id, payload.data)
        commit('SET_RELATIVE',data.relative)
        return data
    }

}

export default  {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}