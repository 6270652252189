import axios from 'axios'
const accessToken = localStorage.getItem('accessToken')
import router from "@/router";
/** Default config for axios instance */
let config = {
    baseURL: process.env.VUE_APP_BASE_URL,
   // timeout: 1000,
    headers:{
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': `Bearer ${accessToken}`,
        'X_CONTENT_LANGUAGE': localStorage.getItem('locale') || process.env.VUE_APP_I18N_LOCALE
    }
};

/** Creating the instance for axios */
const httpClient = axios.create(config);

/** Auth token interceptors */
const authInterceptor = config => {
    /** TODO: Add auth token */
    return config;
};

/** logger interceptors */
const loggerInterceptor = config => {
    /** TODO */
    return config;
}

/** Adding the request interceptors */
httpClient.interceptors.request.use(authInterceptor);
httpClient.interceptors.request.use(loggerInterceptor);
httpClient.interceptors.request.use(
    request => {
        /** TODO: Add any request interceptors */
        request.headers['X_CONTENT_LANGUAGE'] = localStorage.getItem('locale') || process.env.VUE_APP_I18N_LOCALE
        return request;
    }
);
/** Adding the response interceptors */
httpClient.interceptors.response.use(
    response => {
        /** TODO: Add any response interceptors */
        return response;
    },
    error => {
        if (error.response.status === 401)
        {
            router.push({path:'/login'}).catch(() => {})
        }
        return Promise.reject(error);
    }
);

export { httpClient };
