import {httpClient} from "@/plugins/axios";

const API_ROUTE = 'my-office'

export default {
    async myOffice(){
        return await httpClient.get(`${API_ROUTE}`)
    },
    async updateOffice(payload){
        return await httpClient.put(`${API_ROUTE}`,payload)
    },
    async deleteImage(){
        return await httpClient.delete(`${API_ROUTE}/image`)
    },
    async uploadImage(image){
        let form = new FormData;
        form.append('logo',image,image.name)
        return await httpClient.post(`${API_ROUTE}/image`,form,{
            headers:{
                "Content-Type":'multipart/form-data'
            }
        })
    },
    async getWorkingDays(){
        return await httpClient.get(`${API_ROUTE}/working-schedule`)
    },

    async updateWorkingDays(payload){
        return await httpClient.post(`${API_ROUTE}/working-schedule`,payload)
    }


}