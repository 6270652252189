import {RepositoryFactory} from "@/RepositoryFactory";
const profileService = RepositoryFactory.get('profile')
const state = () => ({
    profile: {},
})

// getters
const getters = {
    getProfile(state) {
        return state.profile
    },
}

// mutations
const mutations = {
    SET_PROFILE(state,profile){
        state.profile = profile
    }
}

// actions
const actions = {

    async fetchProfile({commit},id){
        const {data} = await profileService.findOne(id)
        commit('SET_PROFILE',data.profile)
    },
    async updateProfile({commit},payload) {
        const {data} = await profileService.update(payload.patient_id, payload.id, payload.data)
        commit('SET_PROFILE',data.profile)
        return data
    }

}

export default  {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}