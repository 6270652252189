import {RepositoryFactory} from "@/RepositoryFactory";
const medicalDocumentService = RepositoryFactory.get('medicalDocuments')

const state = () => ({
    documents: null,
    document: null,
})

const getters = {
    getDocument :(state) => state.document,
    getDocuments :(state) => state.documents,

    getDocumentList :(state) => {
        if (state.documents === null)
        {
            return []
        }

        if (state.documents?.data)
        {
            return  state.documents?.data;
        }

        return []
    },

    prescription(state){
        if (!state.document)
        {
            return null;
        }

        if (state.document.type !== 'prescription')
        {
            return null;
        }


        return state.document.prescription;
    },

    justification(state){
        if (!state.document)
        {
            return null;
        }

        if (state.document.type !== 'justification')
        {
            return null;
        }


        return state.document.justification;
    },
    getMeta(state){
        if (state.documents === null || !state.documents.meta )
        {
            return {}
        }

        return state.documents.meta
    },
}

const mutations = {
    SET_DOCUMENT(state,document){
        state.document = document
    },
    SET_DOCUMENTS(state,documents){
        state.documents = documents
    },

}

const actions = {
    async findDocuments({commit},query = null){
        const {data} = await medicalDocumentService.find(query)
        commit('SET_DOCUMENTS',data)
    },

    async findDocument({commit},id){
        const {data} = await medicalDocumentService.findOneById(id)
        commit('SET_DOCUMENT',data.document)
        return data;
    },

    async deleteDocument({},id){
        return await medicalDocumentService.delete(id)
    },

}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}