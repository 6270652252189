import auth from "@/middlewares/auth";
import store from "@/store";
import ErrorService from "@/Exceptions/ErrorService";
import {can} from "@/middlewares";

export default [
    {
        path: '/appointments',
        name: 'appointments',
        meta:{
            middleware:[auth,can],
            permissions:['view-list-appointment']
        },
        component: () => import(/* webpackChunkName: "appointments" */ '@/views/appointments/Calendar.vue'),
        children: [
            {
                path: '/appointments/create',
                name: 'appointments-create',
                meta:{
                    middleware:[auth,can],
                    permissions:['create-appointment']
                },
                component: () => import(/* webpackChunkName: "appointments-create" */ '@/views/appointments/Create.vue')
            },
            {
                path: '/appointments/:id/edit',
                name: 'appointments-edit',
                meta:{
                    middleware:[auth,can],
                    permissions:['edit-appointment']
                },
                beforeEnter: async (to, from, next) => {
                    try {
                        await store.dispatch('appointments/fetchAppointment',to.params.id)
                        next()
                    }catch (err)
                    {
                        ErrorService.onError(err).redirect()
                    }
                },
                component: () => import(/* webpackChunkName: "appointments-edit" */ '@/views/appointments/Edit.vue')
            },
            {
                path: '/appointments/requests',
                name: 'appointments-requests',
                meta:{
                    middleware:[auth]
                },
                component: () => import(/* webpackChunkName: "appointments-requests" */ '@/views/appointments/Requests.vue')
            },
        ]
    },
    {
        path: '/appointments/queue',
        name: 'appointments-queue',
        meta:{
            middleware:[auth,can],
            permissions:['view-list-visit', 'view-list-appointment']
        },
        component: () => import(/* webpackChunkName: "appointments-queue" */ '@/views/appointments/Queue.vue'),
        children: [
            {
                path: 'create',
                name: 'appointments-queue-create',
                meta:{
                    middleware:[auth,can],
                    permissions:['create-visit']
                },
                component: () => import(/* webpackChunkName: "appointments-queue" */ '@/views/appointments/QueueCreate.vue')
            }
        ]
    },
]
