import {RepositoryFactory} from "@/RepositoryFactory";
const prescriptionService = RepositoryFactory.get('prescriptions')
const state = () => ({

    prescription: {},
    prescriptions: null,
    timeSlots:{
        isLoading:false,
        slots:[]
    },
})

// getters
const getters = {
    getPrescription(state) {
        return state.prescription
    },
    getPrescriptions(state) {
        return state.prescriptions
    },
    getPrescriptionsList (state){
        if (state.prescriptions === null)
        {
            return []
        }

        if (state.prescriptions?.data)
        {
            return  state.prescriptions?.data;
        }

        return state.prescriptions
    },
    getMeta(state){
        if (state.prescriptions === null || !state.prescriptions.meta )
        {
            return {}
        }

        return state.prescriptions.meta
    },
}

// mutations
const mutations = {
    SET_PRESCRIPTION(state,prescription){
        state.prescription = prescription
    },

    SET_PRESCRIPTIONS(state,prescriptions){
        state.prescriptions = prescriptions
    },

}

// actions
const actions = {
    async fetchPrescriptions({commit,state},query = null){
        let q = {
            ...query
        }
        const {data} = await prescriptionService.find(q)
        commit('SET_PRESCRIPTIONS',data)
    },

    async createPrescription({commit},payload){
        const {data} = await prescriptionService.create(payload.patient_id,payload.data)
        commit('SET_PRESCRIPTION',data.prescription)
        return data;
    },

    async fetchPrescription({commit},id){
        const {data} = await prescriptionService.findOneById(id)
        commit('SET_PRESCRIPTION',data.prescription)
        return data
    },

    async deletePrescription({},id){
        return await prescriptionService.delete(id)
    },

    async updatePrescription({commit},payload) {
        const {data} = await prescriptionService.update(payload.patient_id,payload.id,payload.data)
        commit('SET_PRESCRIPTION',data.prescription)
        return data;
    },
}

export default  {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}