import {RepositoryFactory} from "@/RepositoryFactory";
const treatmentService = RepositoryFactory.get('treatments')

const state = () => ({
    treatment: {},
    treatments: null,
})

const getters = {
    getTreatment :(state) => state.treatment,
    getTreatments :(state) => state.treatments,
    getMeta:(state) => {
        if (state.treatments && state.treatments.meta)
        {
            return state.treatments.meta;
        }

        return {}
    },
    getTreatmentList :(state) => {
        if (state.treatments === null)
        {
            return []
        }

        if (state.treatments?.data)
        {
            return  state.treatments?.data;
        }

        return state.treatments
    },
}

const mutations = {
    SET_TREATMENT(state,treatment){
        state.treatment = treatment
    },
    SET_TREATMENTS(state,treatments){
        state.treatments = treatments
    },

}

const actions = {
    async findTreatments({commit},query = null){
        const {data} = await treatmentService.find(query)
        commit('SET_TREATMENTS',data)
    },

    async findTreatment({commit},id){
        const {data} = await treatmentService.findOneById(id)
        commit('SET_TREATMENT',data.treatment)
    },

    async deleteTreatment({},id){
        return await treatmentService.delete(id)
    },

    async updateTreatment({commit},payload) {
        const {data} = await treatmentService.update(payload.id,payload.data)
        commit('SET_TREATMENT',data.treatment)
        return data;
    },

    async updateTreatmentStatus({commit},payload) {
        const {data} = await treatmentService.updateStatus(payload.id,{status:payload.status})
        //commit('SET_TREATMENT',data.treatment)
        return data;
    },

    async createTreatment({commit},payload) {
        const {data} = await treatmentService.create(payload)
        commit('SET_TREATMENT',data.treatment)
        return data
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}