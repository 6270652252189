import auth from "@/middlewares/auth";
import store from "@/store";
import ErrorService from "@/Exceptions/ErrorService";
import {lang} from "@/middlewares";

export default [
    {
        path: '/settings',
        name: 'settings ',
        meta: {
            middleware: [auth],
        },
        component: () => import(/* webpackChunkName: "settings" */ '../../views/settings/Index.vue'),
        children: [
            {
                path: 'personal/profile',
                name: 'settings-personal-profile',
                meta: {
                    middleware: [auth],
                },
                component: () => import(/* webpackChunkName: "settings" */ '../../views/settings/personal/Profile.vue')
            },
            {
                path: 'personal/password',
                name: 'settings-personal-password',
                meta: {
                    middleware: [auth],
                },
                component: () => import(/* webpackChunkName: "settings" */ '../../views/settings/personal/Password.vue')
            },
            {
                path: 'personal/data',
                name: 'settings-personal-data',
                meta: {
                    middleware: [auth],
                },
                component: () => import(/* webpackChunkName: "settings" */ '../../views/settings/personal/Data.vue')
            },
            {
                path: 'personal/language-and-preferences',
                name: 'settings-personal-language-and-preferences',
                meta: {
                    middleware: [auth],
                },
                component: () => import(/* webpackChunkName: "settings" */ '../../views/settings/personal/LanguageAndPreferences.vue')
            },


            {
                path: 'company/information',
                name: 'settings-company-information',
                beforeEnter: async (to, from, next) => {
                    try {
                        await store.dispatch('office/find',to.params.id)
                        next()
                    }catch (err)
                    {
                        ErrorService.onError(err).redirect()
                    }
                },
                meta: {
                    middleware: [auth],
                },
                component: () => import(/* webpackChunkName: "settings" */ '../../views/settings/company/Information.vue')
            },
            {
                path: 'company/staff',
                name: 'settings-company-staff',
                meta: {
                    middleware: [auth],
                },
                component: () => import(/* webpackChunkName: "settings" */ '../../views/settings/company/staff/Index.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'settings-company-staff-create',
                        meta: {
                            middleware: [auth],
                        },
                        component: () => import(/* webpackChunkName: "settings" */ '../../views/settings/company/staff/Create.vue')
                    },
                    {
                        path: ':id/edit',
                        name: 'settings-company-staff-edit',
                        meta: {
                            middleware: [auth],
                        },
                        beforeEnter: async (to, from, next) => {
                            try {
                                await store.dispatch('user/findUser',to.params.id)
                                next()
                            }catch (err)
                            {
                                ErrorService.onError(err).redirect()
                            }
                        },
                        component: () => import(/* webpackChunkName: "settings" */ '../../views/settings/company/staff/Edit.vue')
                    },
                ]
            },
            {
                path: 'company/roles-and-permissions',
                name: 'settings-company-roles-and-permissions',
                meta: {
                    middleware: [auth],
                },
                component: () => import(/* webpackChunkName: "settings" */ '../../views/settings/company/roles-and-permissions/Index.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'settings-company-roles-and-permissions-create',
                        meta: {
                            middleware: [auth],
                        },
                        component: () => import(/* webpackChunkName: "settings" */ '../../views/settings/company/roles-and-permissions/Create.vue')
                    },
                    {
                        path: ':id/edit',
                        name: 'settings-company-roles-and-permissions-edit',
                        meta: {
                            middleware: [auth],
                        },
                        beforeEnter: async (to, from, next) => {
                            try {
                                await store.dispatch('roles/findRole',to.params.id)
                                next()
                            }catch (err)
                            {
                                ErrorService.onError(err).redirect()
                            }
                        },
                        component: () => import(/* webpackChunkName: "settings" */ '../../views/settings/company/roles-and-permissions/Edit.vue')
                    },
                ]
            },
            {
                path: 'company/working-schedule',
                name: 'settings-company-working-schedule',
                beforeEnter: async (to, from, next) => {
                    try {
                        await store.dispatch('office/findWorkingDays')
                        next()
                    }catch (err)
                    {
                        ErrorService.onError(err).redirect()
                    }
                },
                meta: {
                    middleware: [auth],
                },
                component: () => import(/* webpackChunkName: "settings" */ '../../views/settings/company/WorkingSchedule.vue')
            },
            {
                path: 'company/treatments',
                name: 'settings-company-treatments',
                meta: {
                    middleware: [auth],
                },
                component: () => import(/* webpackChunkName: "settings" */ '../../views/settings/company/treatments/Index.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'settings-company-treatments-create',
                        meta: {
                            middleware: [auth],
                        },
                        component: () => import(/* webpackChunkName: "settings" */ '../../views/settings/company/treatments/Create.vue')
                    },
                    {
                        path: ':id/edit',
                        name: 'settings-company-treatments-edit',
                        beforeEnter: async (to, from, next) => {
                            try {
                                await store.dispatch('treatment/findTreatment',to.params.id)
                                next()
                            }catch (err)
                            {
                                ErrorService.onError(err).redirect()
                            }
                        },
                        meta: {
                            middleware: [auth],
                        },
                        component: () => import(/* webpackChunkName: "settings" */ '../../views/settings/company/treatments/Edit.vue')
                    },
                ]
            },
            {
                path: 'company/automated-messages',
                name: 'settings-company-automated-messages',
                meta: {
                    middleware: [auth],
                },
                component: () => import(/* webpackChunkName: "settings" */ '../../views/settings/company/AutomatedMessages.vue')
            },
            {
                path: 'company/notifications',
                name: 'settings-company-notifications',
                meta: {
                    middleware: [auth],
                },
                component: () => import(/* webpackChunkName: "settings" */ '../../views/settings/company/Notifications.vue')
            },
        ]
    },
]
