import {httpClient} from "@/plugins/axios";

export default class DashboardRepository {
    constructor(api_route = 'dashboard') {
        this.API_ROUTE = api_route;
    }
    async find(query = {}){
        return await httpClient.get(this.API_ROUTE,{params:query})
    }

}