import {RepositoryFactory} from "@/RepositoryFactory";
const appointmentService = RepositoryFactory.get('appointments')
const state = () => ({
    dateRange: {
        start_date:null,
        end_date:null
    },
    appointment: {},
    appointments: null,
    timeSlots:{
        isLoading:false,
        slots:[]
    },
})

// getters
const getters = {
    getAppointment(state) {
        return state.appointment
    },
    getAppointments(state) {
        return state.appointments
    },
    getDateRange(state) {
        return state.dateRange
    },
    getAppointmentsList (state){
        if (!state.appointments)
        {
            return []
        }

        if (state.appointments?.data)
        {
            return  state.appointments?.data;
        }

        return state.appointments
    },
    getTimeSlots(state){
        return  state.timeSlots
    },
    getMeta(state){
        if (state.appointments === null || !state.appointments.meta )
        {
            return {}
        }

        return state.appointments.meta
    },
}

// mutations
const mutations = {
    SET_APPOINTMENT(state,appointment){
        state.appointment = appointment
    },
    SET_DATE_RANGE(state,range){
        state.dateRange = range
    },
    SET_APPOINTMENTS(state,appointments){
        state.appointments = appointments
    },
    SET_TIME_SLOTS(state,slots){
        state.timeSlots.slots = slots;
    },
    SET_TIME_SLOTS_Loading(state,value){
        state.timeSlots.isLoading = value;
    }
}

// actions
const actions = {
    async fetchAppointments({commit,state},query = null){
        let q = {
            ...state.dateRange,
            ...query
        }
        const {data} = await appointmentService.find(q)
        commit('SET_APPOINTMENTS',data)
    },

    async createAppointment({commit},payload){
        const {data} = await appointmentService.create(payload)
        commit('SET_APPOINTMENT',data.appointment)
        return data;
    },

    async fetchAppointment({commit},id){
        const {data} = await appointmentService.findOneById(id)
        commit('SET_APPOINTMENT',data.appointment)
    },

    async deleteAppointment({},id){
        return await appointmentService.delete(id)
    },

    async updateAppointment({commit},payload) {
        const {data} = await appointmentService.update(payload.id,payload.data)
        commit('SET_APPOINTMENT',data.appointment)
        return data;
    },

    async findTimeSlots({commit,state},payload){
        commit('SET_TIME_SLOTS_Loading',true);
        commit('SET_TIME_SLOTS',[]);
        try {
            const {data} = await appointmentService.availableSlots(payload)
            commit('SET_TIME_SLOTS',data.slots)
            return data;
        }catch (e) {
            throw e
        }finally
        {
            commit('SET_TIME_SLOTS_Loading',false);
        }

    }

}

export default  {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}