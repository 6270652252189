import {httpClient} from "@/plugins/axios";

export default class BaseRepository {
    constructor(api_route = '') {
        this.API_ROUTE = api_route;
    }
    async find(query = {}){
        return await httpClient.get(this.API_ROUTE,{params:query})
    }
    async findOneById(id){
        return await httpClient.get(`${this.API_ROUTE}/${id}`)
    }
    async create(payload){
        return await httpClient.post(this.API_ROUTE,payload)
    }
    async update(id,payload){
        return await httpClient.put(`${this.API_ROUTE}/${id}`,payload)
    }
    async delete(id){
        return await httpClient.delete(`${this.API_ROUTE}/${id}`)
    }
}


