import {RepositoryFactory} from "@/RepositoryFactory";
const userService = RepositoryFactory.get('users')

const state = () => ({
    user: {},
    users: null,
    doctors: null,
})

const getters = {
    getUser :(state) => state.user,
    getUsers :(state) => state.users,
    getDoctors :(state) => state.doctors,
    getDoctorList :(state) => {
        if (state.doctors === null)
        {
            return []
        }

        if (state.doctors?.data)
        {
            return  state.doctors?.data;
        }

        return state.doctors
    },
    getUserList :(state) => {
        if (state.users === null)
        {
            return []
        }

        if (state.users?.data)
        {
            return  state.users?.data;
        }

        return state.users
    },

    getMeta(state){
        if (state.users === null || !state.users.meta )
        {
            return {}
        }

        return state.users.meta
    },
}

const mutations = {
    SET_USER(state,user){
        state.user = user
    },
    SET_USERS(state,users){
        state.users = users
    },
    SET_DOCTORS(state,doctors){
        state.doctors = doctors
    }
}

const actions = {
    async findUsers({commit},query = null){
        const {data} = await userService.find(query)
        commit('SET_USERS',data)
    },

    async findDoctors({commit},query = null){
        const {data} = await userService.findDoctors(query)
        commit('SET_DOCTORS',data)
    },

    async findUser({commit},id){
        const {data} = await userService.findOneById(id)
        commit('SET_USER',data.user)
    },

    async deleteUser({},id){
        return await userService.delete(id)
    },

    async updateUser({commit},payload) {
        const {data} = await userService.update(payload.id,payload.data)
        commit('SET_USER',data.user)
        return data;
    },


    async createUser({commit},payload){
        const {data} = await userService.create(payload)
        commit('SET_USER',data.user)
        return data;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}