import {RepositoryFactory} from "@/RepositoryFactory";
const paymentService = RepositoryFactory.get('payments')
const state = () => ({
    payment: {},
    payments: null,

})

// getters
const getters = {
    getPayment(state) {
        return state.payment
    },
    getPayments(state) {
        return state.payments
    },
    getPaymentsList (state){
        if (state.payments === null)
        {
            return []
        }

        if (state.payments?.data)
        {
            return  state.payments?.data;
        }

        return state.payments
    },
    getMeta(state){
        if (state.payments === null || !state.payments.meta )
        {
            return {}
        }

        return state.payments.meta
    },
}

// mutations
const mutations = {
    SET_PAYMENT(state,payment){
        state.payment = payment
    },
    SET_PAYMENTS(state,payments){
        state.payments = payments
    },

}

// actions
const actions = {
    async fetchPayments({commit,state},query = null){
        let q = {
            ...query
        }
        const {data} = await paymentService.find(q)
        commit('SET_PAYMENTS',data)
    },

    async createPayment({commit},payload){
        const {data} = await paymentService.create(payload)
        commit('SET_PAYMENT',data.payment)
        return data;
    },

    async fetchPayment({commit},id){
        const {data} = await paymentService.findOneById(id)
        commit('SET_PAYMENT',data.payment)
    },

    async deletePayment({},id){
        return await paymentService.delete(id)
    },

    async updatePayment({commit},payload) {
        const {data} = await paymentService.update(payload.id,payload.data)
        commit('SET_PAYMENT',data.payment)
        return data;
    },

}

export default  {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}