import {RepositoryFactory} from "@/RepositoryFactory";
const documentService = RepositoryFactory.get('documents')
const state = () => ({
    document: {},
    documents: null,
    loading:false,
})

// getters
const getters = {
    getDocument(state) {
        return state.document
    },
    isLoading(state){
      return state.loading
    },
    getDocuments(state) {
        return state.documents
    },
    getLastDocuments(state,getters){
        return getters.getDocumentsList.slice(0,3)
    },
    getDocumentsList (state){
        if (state.documents === null)
        {
            return []
        }

        if (state.documents?.data)
        {
            return  state.documents?.data;
        }

        return state.documents ?? []
    },
    getMeta(state){
        if (state.documents === null || !state.documents.meta )
        {
            return {}
        }

        return state.documents.meta
    },
}

// mutations
const mutations = {
    SET_DOCUMENT(state,document){
        state.document = document
    },
    SET_DOCUMENTS(state,documents){
        state.documents = documents
    },
    SET_LOADING(state,value){
        state.loading = value
    }
}

// actions
const actions = {
    async fetchDocuments({commit},payload){
        commit('SET_LOADING',true);
        try {
            const {data} = await documentService.find(payload.patient_id,payload.query)
            commit('SET_DOCUMENTS',data)
        }catch (err){
            throw err
        }finally{
            commit('SET_LOADING',false);
        }
    },

    async fetchDocument({commit},id){
        const {data} = await documentService.findOneById(id)
        commit('SET_MEDICAL_DOCUMENT',data.medicalDocument)
    },

    async deleteDocument({},payload){
        return await documentService.delete(payload.patient_id,payload.id)
    },

    async createDocument({commit},payload){
        let form = new FormData;
        form.append('name',payload.name);
        form.append('file',payload.file,payload.file.name)

        const {data} = await documentService.create(payload.patient_id,form)
        return data;
    },

}

export default  {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}