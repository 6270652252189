import Vue from 'vue'
import VueRouter from 'vue-router'
import DashboardView from "@/views/DashboardView";
import auth from "@/middlewares/auth";
import store from "@/store";

import routeList from '@/router/routes'
import {lang, middlewarePipeline, prefixGlobalMiddlewares, suffixGlobalMiddlewares} from "@/middlewares";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: DashboardView,
        meta: {
            middleware: [
                auth
            ]
        }
    },
    ...routeList,
    {
        path: '/messages',
        name: 'messages',
        component: () => import(/* webpackChunkName: "messages" */ '../views/messages/Index.vue')
    },
    {
        path: '*',
        name: 'not-found',
        component: () => import(/* webpackChunkName: "not-found" */ '@/views/errors/NotFound.vue')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {

    let middlewares = to.meta.middleware ?? []

    middlewares = [...prefixGlobalMiddlewares, ...middlewares, ...suffixGlobalMiddlewares]

    if (middlewares.length === 0) {
        return next()
    }

    const context = {
        to,
        from,
        next,
        store
    }
    return middlewares[0]({
        ...context,
        next: middlewarePipeline(context, middlewares, 1)
    })
})


export default router
