import {RepositoryFactory} from "@/RepositoryFactory";

const visitService = RepositoryFactory.get('visits')
const state = () => ({
    dateRange: {
        start_date: null,
        end_date: null
    },
    visit: {},
    visits: null,
    appointmentVisits: null,
    states: {
        done: 0,
        pending: 0,
        canceled: 0,
    },
    queue: {
        visits: {
            visits: [],
            appointments: [],
            done: [],
            progress: [],
            canceled: [],
        },
        states: {
            done: 0,
            pending: 0,
            canceled: 0,
            progress: 0,
            pendingVisits: 0,
            pendingAppointments: 0
        }

    },
    visitsCount: 0,
    appointmentsCount: 0,
})

// getters
const getters = {
    getVisit(state) {
        return state.visit
    },
    getVisits(state) {
        return state.visits
    },
    getAppointmentVisitsList(state) {
        if (state.appointmentVisits === null) {
            return []
        }

        if (state.appointmentVisits?.data) {
            return state.appointmentVisits?.data;
        }

        return state.appointmentVisits
    },
    getVisitsStates(state) {
        return state.states
    },
    getQueueStates(state) {
        return state.queue.states
    },

    getQueueVisits: (state) => (status) => {
        return state.queue.visits[status] ?? {}
    },

    getAppointmentsCount(state) {
        return state.appointmentsCount
    },

    getVisitsCount(state) {
        return state.visitsCount
    },

    getDateRange(state) {
        return state.dateRange
    },
    getVisitsList(state) {
        if (state.visits === null) {
            return []
        }

        if (state.visits?.data) {
            return state.visits?.data;
        }

        return state.visits
    },
    getQueueData(state)
    {
        return state.queue
    },
    getMeta(state) {
        if (state.visits === null || !state.visits.meta) {
            return {}
        }

        return state.visits.meta
    },
}

// mutations
const mutations = {
    SET_VISIT(state, visit) {
        state.visit = visit
    },
    SET_DATE_RANGE(state, range) {
        state.dateRange = range
    },
    SET_VISITS(state, visits) {
        state.visits = visits
    },
    SET_APPOINTMENT_VISITS(state, visits) {
        state.appointmentVisits = visits
    },
    SET_VISIT_STATES(state, states) {
        state.states = states
    },
    SET_VISITS_COUNT(state, data) {
        state.visitsCount = data.visits_count
    },
    SET_APPOINTMENTS_COUNT(state, data) {
        state.appointmentsCount = data.visits_count
    },
    SET_QUEUE_DATA(state, data) {
        state.queue.states = data.states
        state.queue.states.pendingVisits = data.states.pending_visits
        state.queue.states.pendingAppointments = data.states.pending_appointments

        state.queue.visits = data.queue
    },
}

// actions
const actions = {
    async fetchVisits({commit, state}, query = null) {
        let q = {
            ...query
        }
        const {data} = await visitService.find(q)
        commit('SET_VISITS', data)
    },

    async fetchAppointmentVisits({commit, state}, query = null) {
        let q = {
            ...query,
            'has_appointment': true
        }
        const {data} = await visitService.find(q)
        commit('SET_APPOINTMENT_VISITS', data)
    },

    async fetchVisitsStates({commit, state}, query = null) {
        let q = {
            ...query
        }

        const {data} = await visitService.getStates(q)
        commit('SET_VISIT_STATES', data)
    },

    async fetchQueueData({commit, state}, query = null) {
        let q = {
            ...query
        }

        const {data} = await visitService.getQueueData(q)
        commit('SET_QUEUE_DATA', data)
    },

    async createVisit({commit}, payload) {
        const {data} = await visitService.create(payload)
        commit('SET_VISIT', data.visit)
        return data;
    },

    async fetchVisit({commit}, id) {
        const {data} = await visitService.findOneById(id)
        commit('SET_VISIT', data.visit)
    },

    async deleteVisit({}, id) {
        await visitService.delete(id)
    },

    async updateVisit({commit}, payload) {
        const {data} = await visitService.update(payload.id, payload.data)
        commit('SET_VISIT', data.visit)
        return data;
    },

    async updateVisitState({commit}, payload) {
        const {data} = await visitService.updateState(payload.id, payload.data)
        commit('SET_VISIT', data.visit)
        return data;
    },

    async fetchVisitsCount({commit, state}, query = null) {
        let q = {
            ...query,
            ...{has_appointment: false}
        }

        const {data} = await visitService.getVisitsCount(q)
        commit('SET_VISITS_COUNT', data)
    },

    async fetchAppointmentsCount({commit, state}, query = null) {
        let q = {
            ...query,
            ...{has_appointment: true}
        }

        const {data} = await visitService.getVisitsCount(q)
        commit('SET_APPOINTMENTS_COUNT', data)
    },

}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}