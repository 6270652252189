import {RepositoryFactory} from "@/RepositoryFactory";
const roleService = RepositoryFactory.get('roles')

const state = () => ({
    role: {},
    roles: null,
})

const getters = {
    getRole :(state) => state.role,
    getRoles :(state) => state.roles,

    getRoleList :(state) => {
        if (state.roles === null)
        {
            return []
        }

        if (state.roles?.data)
        {
            return  state.roles?.data;
        }

        return state.roles
    },
    getMeta(state){
        if (state.roles === null || !state.roles.meta )
        {
            return {}
        }

        return state.roles.meta
    },
}

const mutations = {
    SET_ROLE(state,role){
        state.role = role
    },
    SET_ROLES(state,roles){
        state.roles = roles
    },

}

const actions = {
    async findRoles({commit},query = null){
        const {data} = await roleService.find(query)
        commit('SET_ROLES',data)
    },

    async create({commit},payload){
        const {data} = await roleService.create(payload)
        commit('SET_ROLE',data.role)
        return data;
    },

    async findRole({commit},id){
        const {data} = await roleService.findOneById(id)
        commit('SET_ROLE',data.role)
    },

    async deleteRole({},id){
        return await roleService.delete(id)
    },

    async updateRole({commit},payload) {
        const {data} = await roleService.update(payload.id,payload.data)
        commit('SET_ROLE',data.role)
        return data;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}