import PatientRepository from "@/RepositoryFactory/repositories/Patient/PatientRepository";
import RelativeRepository from "@/RepositoryFactory/repositories/Patient/RalativesRepository";
import DocumentsRepository from "@/RepositoryFactory/repositories/Patient/DocumentsRepository";
import authRepository from "@/RepositoryFactory/repositories/authRepository";
import AppointmentsRepository from "@/RepositoryFactory/repositories/AppointmentsRepository";
import VisitsRepository from "@/RepositoryFactory/repositories/VisitsRepository";
import UsersRepository from "@/RepositoryFactory/repositories/UsersRepository";
import TreatmentsRepository from "@/RepositoryFactory/repositories/TreatmentsRepository";
import DrugsRepository from "@/RepositoryFactory/repositories/DrugsRepository";
import PaymentsRepository from "@/RepositoryFactory/repositories/PaymentsRepository";
import DashboardRepository from "@/RepositoryFactory/repositories/DashboardRepository";
import OfficeRepository from "@/RepositoryFactory/repositories/OfficeRepository";
import ProfileRepository from "@/RepositoryFactory/repositories/Patient/ProfileRepository";
import MedicationRepository from "@/RepositoryFactory/repositories/Patient/MedicationsRepository";
import PrescriptionsRepository from "@/RepositoryFactory/repositories/Patient/PrescriptionsRepository";
import JustificationsRepository from "@/RepositoryFactory/repositories/Patient/JustificationsRepository";
import AllergiesRepository from "@/RepositoryFactory/repositories/Patient/AllergiesRepository";
import ChronicDiseasesRepository from "@/RepositoryFactory/repositories/Patient/ChronicDiseasesRepository";
import MedicalDocumentsRepository from "@/RepositoryFactory/repositories/MedicalDocumentsRepository";
import HospitalizationRepository from "@/RepositoryFactory/repositories/Patient/HospitalizationsRepository";
import RolesRepository from "@/RepositoryFactory/repositories/RolesRepository";
import PatientNoteRepository from "@/RepositoryFactory/repositories/Patient/PatientNoteRepository";

const repositories = {
    dashboard: new DashboardRepository(),
    patients: new PatientRepository,
    prescriptions: new PrescriptionsRepository(),
    documents: new DocumentsRepository,
    relatives: new RelativeRepository,
    users: new UsersRepository,
    treatments: new TreatmentsRepository,
    appointments: new AppointmentsRepository,
    visits: new VisitsRepository,
    drugs: new DrugsRepository,
    payments: new PaymentsRepository,
    profile: new ProfileRepository,
    medications: new MedicationRepository,
    hospitalizations: new HospitalizationRepository,
    allergies: new AllergiesRepository,
    chronicDiseases: new ChronicDiseasesRepository,
    auth:authRepository,
    office:OfficeRepository,
    medicalDocuments: new MedicalDocumentsRepository(),
    justifications: new JustificationsRepository(),
    roles: new RolesRepository(),
    patientNote: new PatientNoteRepository(),
}

export const RepositoryFactory = {
    get: name => repositories[name]
}