import store from "@/store";
import ErrorService from "@/Exceptions/ErrorService";
import {auth,can} from "@/middlewares";

export default [
    {
        path: '/payments',
        name: 'payments',
        meta: {
            middleware: [auth,can],
            permissions:['view-list-payment']
        },
        component: () => import(/* webpackChunkName: "payments" */ '@/views/payments/Index.vue'),
        children: [
            {
                path: 'create',
                name: 'payments-create',
                meta: {
                    middleware: [auth,can],
                    permissions:['create-payment']
                },
                component: () => import(/* webpackChunkName: "payments" */ '@/views/payments/Create.vue'),
            },
            {
                path: '/:id/edit',
                name: 'payments-edit',
                meta: {
                    middleware: [auth,can],
                    permissions:['edit-payment']
                },
                beforeEnter: async (to, from, next) => {
                    try {
                        await store.dispatch('payment/fetchPayment',to.params.id)
                        next()
                    }catch (err)
                    {
                        ErrorService.onError(err).redirect()
                    }
                },
                component: () => import(/* webpackChunkName: "payments" */ '@/views/payments/Edit.vue'),

            }
        ]
    },
]
