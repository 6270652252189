import {httpClient} from "@/plugins/axios";
const state = () => ({
    loading:false,
    setting: null,
    permissions:[],
})

// getters
const getters = {
    getSettings :(state) => state.setting,
    hospitalizationSettings :(state) => {
        if (state.loading || !state.setting)
        {
            return {};
        }
        return  state.setting?.hospitalization ?? {}
    },
    drugSettings :(state) => {
        if (state.loading || !state.setting)
        {
            return {};
        }
        return  state.setting?.drugs ?? {}
    },
    allergySettings :(state) => {
        if (state.loading || !state.setting)
        {
            return {};
        }
        return  state.setting?.allergy ?? {}
    },
    accountTypes: (state) => {
        if (state.loading || !state.setting)
        {
            return {};
        }
        return  state.setting?.account_types ?? {}
    },
    days :(state) => {
        if (state.loading || !state.setting)
        {
            return [];
        }
        return  state.setting?.days ?? []
    },
    religions:(state) => {
        if (state.loading || !state.setting)
        {
            return {};
        }
        return  state.setting?.religions ?? {}
    },
    office:(state) =>{
        if (state.loading || !state.setting)
        {
            return {};
        }
        return  state.setting.office ?? {}
    },
    relationships:(state) => {
        if (state.loading || !state.setting)
        {
            return {};
        }
        return  state.setting?.relationships ?? {}
    },
    maritalStatus:(state) => {
        if (state.loading || !state.setting)
        {
            return {};
        }
        return  state.setting?.marital_status ?? {}
    },
    gender:(state) => {
        if (state.loading || !state.setting)
        {
            return {};
        }
        return  state.setting?.gender ?? {}
    },
    languages:(state) => {
        if (state.loading || !state.setting)
        {
            return {};
        }
        return  state.setting?.languages ?? {}
    },
    profileMeta:(state) => {
        if (state.loading || !state.setting)
        {
            return {};
        }
        return  state.setting.profile.meta ?? {}
    },
    getPermissions(state){
        return state.permissions
    }

}



// mutations
const mutations = {
    SET_SETTING(state,options) {
        state.setting = options
    },
    SET_LOADING (state,value){
        state.loading = value
    },
    SEt_PERMISSIONS(state,value){
        state.permissions = value
    }
}

// actions
const actions = {
    async syncSettings({commit}){
        try {
            commit('SET_LOADING',true)
            const {data} = await httpClient.get('/settings');
            commit('SET_SETTING',data.settings)
        }catch (err) {
            console.log(err)
        }finally {
            commit('SET_LOADING',false)
        }
    },
    async getPermissions({commit}){
        try {
            const {data} = await httpClient.get('/permissions');
            commit('SEt_PERMISSIONS',data.permissions)
        }catch (err) {
            console.error(err)
        }finally {
        }
    },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}




