import {can,canNot,canAny} from '@/Helpers/PermissionService'

const permission = {
    install(Vue, options) {
        Vue.prototype.can = can;
        Vue.prototype.canNot = canNot;
        Vue.prototype.canAny = canAny;
    },
}

export default permission;