import {canAny} from "@/Helpers/PermissionService";
export  default async function can ({to, next, store }){

    if (to.meta.permissions && to.meta.permissions.length > 0) {
        let isAllowed = canAny(to.meta.permissions)
        if (! isAllowed) return next('/')
    }

    return next()
}
