import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import store from "@/store"
import alertPlugin from "@/plugins/AlertPlugin";
import permission from "@/plugins/PermissionPlugin";
import {httpClient} from "@/plugins/axios";
import i18n from './i18n'
import "./plugins/VeeValidate"

Vue.use(alertPlugin)
Vue.use(permission)

Vue.prototype.$httpClient = httpClient;
Vue.config.productionTip = false
const app = new Vue({
  router,
  vuetify,
  i18n,
  store,
  render: h => h(App)
})

app.$mount('#app');

