import store from "@/store";


const notify = async (options = {}) => {
    await store.dispatch('alert/showAlert',options)
}


const success = async (text,timeout = 3000) => {
    const options = {
        text:text,
        timeout:timeout,
        type:"success",
        title:"Success",
    }
    await notify(options)
}

const error = async (text,timeout = 3000) => {
    const options = {
        text:text,
        timeout:timeout,
        type:"error",
        title:"Error!!!",
    }
    await notify(options)
}


export  {
    error,success,notify
}

