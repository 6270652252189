<template>
  <v-snackbar v-model="snackbar" color="white" multi-line :timeout="timeout"   @input="handleAlert">
    <v-row class="py-4 py-lg-0" align="center" justify="space-between">
      <v-col class="text-center d-none d-lg-block" cols="12" lg="2">
        <img v-if="type === 'success'" src="@/assets/create.svg" alt="success" width="100%">
        <img v-else-if="type === 'error'" src="@/assets/error.svg" alt="success" width="100%">
      </v-col>
      <v-col class="black--text text-center text-lg-start " cols="12" lg="7">
        <h2 class="blue--text font-weight-medium" v-if="alertTitle">
          <span   :class="classObject">
            {{ alertTitle }}
          </span>
        </h2>
        <h3 class="font-weight-regular mt-2" v-if="alertText">
            {{ alertText }}
        </h3>
      </v-col>
      <v-col cols="12" lg="3">
        <v-btn class="blue--text" color="blue lighten-5" elevation="0" @click="close()" block>
          got it
        </v-btn>
      </v-col>
    </v-row>
  </v-snackbar>

</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "AlertMessage",
  data: () => ({
    snackbar: false
  }),
  mounted() {},
  watch:{
      isShown:{
          handler(val){
              this.snackbar = val
          },
          deep:true,
      }

  },
  computed:{
      ...mapGetters({
          alertText:'alert/text',
          alertTitle:'alert/title',
          type:'alert/type',
          isShown:'alert/isShown',
          timeout:'alert/timeout',
      }),
      classObject(){
          return {
              'red--text': this.type === 'error',
          }
      }
  },
    methods:{
        ...mapActions({
            clearAlert:'alert/clearAlert'
        }),
        close(){
            this.clearAlert();
        },
        handleAlert(val){
            console.log(val)
            if (val)
            {

            }
        }
    }
}
</script>

<style scoped>

</style>