import router from "@/router";
import {error as errorAlert} from "@/Helpers/AlertService";

export default class ErrorService {
    constructor(error) {
        // this.initHandler();
        this.error = error;
    }

    static onError(error) {
        console.error(error)
        let errorHandler = new ErrorService(error)
        const response = errorHandler.error.response;
        if (response && response.status >= 400 && response.status < 405) {
            // You can handle this differently

            //ErrorService.sentryLogEngine(error);
            return errorHandler;
        }
        // Send Error to Log Engine e.g LogRocket
        //ErrorService.logRocketLogEngine(error);

        return errorHandler;
    }

    redirect(redirectTo = null){

        if (redirectTo)
        {
            router.push(redirectTo).catch(() => {})
            return this;
        }

        let response = this.error.response;

        if (!response)
        {
            router.push({name:'internal-error'}).catch(() => {})
            return this;
        }

        switch (response.status)
        {
            case 404:
                router.push({name:'not-found'}).catch(() => {});
                break;
            case 403:
                router.push({name:'forbidden'}).catch(() => {});
                break;
            case 401:
                router.push({name:'Login'}).catch(() => {});
                break;
            case 500:
                router.push({name:'internal-error'}).catch(() => {});
                break;
            case 503:
                router.push({name:'unavailable'}).catch(() => {});
                break;

        }

        return this;
    }

    static onWarn(error) {
        // Send Error to Log Engine e.g LogRocket
        this.logRocketLogEngine(error);
    }

    static onInfo(error) {
        // You can handle this differently
        this.sentryLogEngine(error);
    }

    static onDebug(error) {
        const response = error.response;
        if (response && response.status >= 400 && response.status < 405) {
            // You can handle this differently
            this.sentryLogEngine(error);
            return false;
        }
        // Send Error to Log Engine e.g LogRocket
        this.logRocketLogEngine(error);
    }

    static initHandler() {
        const scope = this;
        window.onerror = (message, url, lineNo, columnNo, error) => {
            if (error) {
                scope.onError(error);
                console.log(message, url, lineNo, columnNo, error);
            }
        };
    }

    displayErrorAlert() {
        const message = this.error.response?.data.message
        if (message)
        {
            errorAlert(message)
        }
        return this;
    }

    displayValidationError(observerRef) {
        const errors = this.error.response?.data?.errors
        if (errors)
        {
            observerRef.setErrors(errors)
        }
        return this;
    }

    static logRocketLogEngine(error) {
        // Implement LogRocket Engine here
        console.log(error, "LogRocket");
    }

    static sentryLogEngine(error) {
        // Implement Sentry Engine here
        console.log(error, "Sentry");
    }
}