import auth from "@/middlewares/auth";
import guest from "@/middlewares/guest";
import can from "@/middlewares/can";
import lang from "@/middlewares/lang";
import isDoctor from "@/middlewares/isDoctor";

const middlewarePipeline  = (context, middleware, index) => {
    const nextMiddleware = middleware[index]

    if(!nextMiddleware){
        return context.next
    }

    return () => {
        const nextPipeline = middlewarePipeline(
            context, middleware, index + 1
        )

        nextMiddleware({ ...context, next: nextPipeline })

    }
}

/** Gets executed before normal middlewares */
const prefixGlobalMiddlewares = [

]

 /** Gets executed after normal middlewares */
const suffixGlobalMiddlewares = [
    lang
]


export  {
    middlewarePipeline,
    auth,
    guest,
    isDoctor,
    lang,
    can,
    suffixGlobalMiddlewares,
    prefixGlobalMiddlewares,

}