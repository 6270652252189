import {RepositoryFactory} from "@/RepositoryFactory";
const chronicDiseaseService = RepositoryFactory.get('chronicDiseases')
const state = () => ({
    chronicDisease: {},
    chronicDiseases: null,
})

// getters
const getters = {
    getChronicDisease(state) {
        return state.chronicDisease
    },
    getChronicDiseases(state) {
        return state.chronicDiseases
    },
    getChronicDiseasesList (state){
        if (state.chronicDiseases === null)
        {
            return []
        }

        if (state.chronicDiseases?.data)
        {
            return  state.chronicDiseases?.data;
        }

        return state.chronicDiseases
    },
    getMeta(state){
        if (state.chronicDiseases === null || !state.chronicDiseases.meta )
        {
            return {}
        }

        return state.chronicDiseases.meta
    },
}

// mutations
const mutations = {
    SET_CHRONIC_DISEASE(state,chronicDisease){
        state.chronicDisease = chronicDisease
    },
    SET_CHRONIC_DISEASES(state,chronicDiseases){
        state.chronicDiseases = chronicDiseases
    }
}

// actions
const actions = {
    async fetchChronicDiseases({commit},payload){
        const {data} = await chronicDiseaseService.find(payload.patient_id,payload.query)
        commit('SET_CHRONIC_DISEASES',data)
    },

    async createChronicDisease({commit},payload){
        const {data} = await chronicDiseaseService.create(payload.patient_id,payload.data)
        commit('SET_CHRONIC_DISEASE',data.chronicDisease)
        return data
    },

    async fetchChronicDisease({commit},payload){
        const {data} = await chronicDiseaseService.findOneById(payload.patient_id, payload.id)
        commit('SET_CHRONIC_DISEASE',data.chronic_disease)
    },

    async deleteChronicDisease({},payload){
        return await chronicDiseaseService.delete(payload.patient_id, payload.id)
    },

    async updateChronicDisease({commit},payload) {
        const {data} = await chronicDiseaseService.update(payload.patient_id, payload.id, payload.data)
        commit('SET_CHRONIC_DISEASE',data.chronicDisease)
        return data
    }

}

export default  {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}