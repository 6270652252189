import patients from "@/router/routes/patients";
import auth from "@/router/routes/auth";
import appointments from "@/router/routes/appointments";
import medicalDocuments from "@/router/routes/medical-documents";
import drugs from "@/router/routes/drugs";
import settings from "@/router/routes/settings";
import payments from "@/router/routes/payments";
export  default [
    ...auth,
    ...patients,
    ...appointments,
    ...medicalDocuments,
    ...drugs,
    ...settings,
    ...payments,
]
