import {httpClient} from "@/plugins/axios";
import router from "@/router";
export  default async function auth ({to, next, store }){
    const accessToken = localStorage.getItem('accessToken')
    if(!accessToken){
        return router.push({name:'Login'}).catch(() => {})
    }

    httpClient.defaults.headers['Authorization'] = `Bearer ${accessToken}`;

    if (!store.getters['auth/isAuthenticated'])
    {
        await store.dispatch('auth/syncAuth')
    }

    if (store.getters['auth/isAuthenticated'] && !store.getters['globalSettings/getSettings'])
    {
        await store.dispatch('globalSettings/syncSettings')
    }


    return next()
}
