import BaseRepository from "@/RepositoryFactory/repositories/baseRepository";
import {httpClient} from "@/plugins/axios";

export default class PrescriptionsRepository extends BaseRepository{
    constructor() {
        super('patients');
    }

    /**
     * @param patientId
     * @param query
     * @returns {Promise<*>}
     */
    async find(patientId,query = {})
    {
        this.API_ROUTE = `/patients/${patientId}/prescriptions`
        return super.find(query);
    }

    async findOneById(patientId,id) {
        this.API_ROUTE = `/patients/${patientId}/prescriptions`
        return super.findOneById(id);
    }

    async create(patientId,payload) {
        this.API_ROUTE = `/patients/${patientId}/prescriptions`
        return await httpClient.post(this.API_ROUTE,payload);
    }

    async update(patientId,prescriptionId,payload) {
        if (!patientId)
        {
            throw new Error('Patient ID is required')
        }

        if (!prescriptionId)
        {
            throw new Error('Prescription ID is required')
        }

        this.API_ROUTE = `/patients/${patientId}/prescriptions/${prescriptionId}`
        return await httpClient.put(this.API_ROUTE,payload);
    }

    async delete(patientId,id) {
        this.API_ROUTE = `/patients/${patientId}/prescriptions`
        return super.delete(id);
    }
}