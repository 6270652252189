import router from "@/router";
import {RepositoryFactory} from "@/RepositoryFactory";
const officeService = RepositoryFactory.get('office')

const state = () => ({
    office:null,
    workingDays:null,
})

const getters = {
    getOffice :(state) => state.office,
    getWorkingDays(state) { return state.workingDays ?? []}
}

const mutations = {
    SET_OFFICE(state,office){
        state.office = office
    },
    SET_WORKING_DAYS(state,days){
        state.workingDays = [];

        if (Array.isArray(days))
        {
            state.workingDays = days
        }
        else {
            Object.keys(days).forEach(key => {
                state.workingDays.push(days[key])
            })
        }
    }
}

const actions = {
    async find({commit}){
        const {data} = await officeService.myOffice();
        commit('SET_OFFICE',data.office)
        return data;
    },

    async update({commit},payload){
        const {data} = await officeService.updateOffice(payload);
        commit('SET_OFFICE',data.office);
        return data;
    },

    async addImage({commit},image){
        const {data} = await officeService.uploadImage(image)
        commit('SET_OFFICE',data.office)
        return data;
    },

    async deleteImage({commit}){
        const {data} = await officeService.deleteImage()
        commit('SET_OFFICE',data.office)
        return data;
    },

    async findWorkingDays({commit}){
        const {data} = await officeService.getWorkingDays();
        console.log(data)
        commit('SET_WORKING_DAYS',data.working_days)
        return data;
    },

    async syncWorkingDays({commit},payload){
        const {data} = await officeService.updateWorkingDays(payload);
        commit('SET_WORKING_DAYS',data.working_days);
        return data;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}