import {httpClient} from "@/plugins/axios";
import i18n from "@/i18n";
import { localize , localeChanged} from 'vee-validate';

export  default async function auth ({to, next, store }){

    let user = store.getters['auth/getUser']
    let locale
    let isAuth = store.getters['auth/isAuthenticated']

    if(isAuth)
    {
        locale = user.settings.locale
    }else{
        locale = localStorage.getItem('locale') || process.env.VUE_APP_I18N_LOCALE
    }

    localStorage.setItem('locale', locale)
    i18n.locale = locale
    httpClient.defaults.headers.common['X_CONTENT_LANGUAGE'] = locale

    return next()
}
