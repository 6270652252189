<template>
  <v-app id="inspire">

    <template v-if="isAuthenticated">
      <v-navigation-drawer v-model="drawer" app color="blue" dark>
          <v-list>
            <v-list-item>
              <v-skeleton-loader :loading="loader" type="avatar">
                <v-list-item-avatar color="orange">
                  <v-img v-if="authUser.image" :src="authUser.image_url"></v-img>
                  <span class="white--text font-weight-medium" v-else>
                    {{ authUser.first_name?.charAt(0) + authUser.last_name?.charAt(0) }}
                </span>
                </v-list-item-avatar>
              </v-skeleton-loader>
              <v-list-item-content>
                <v-skeleton-loader :class="loader ? 'ms-3' : 'ms-0'" :loading="loader" type="paragraph">
                  <v-list-item-title class="font-weight-medium">
                    {{ authUser.last_name?.charAt(0) + '.' + authUser.first_name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ authUser.email ?? '' }}</v-list-item-subtitle>
                </v-skeleton-loader>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        <v-divider></v-divider>
        <v-list nav dense>
          <v-skeleton-loader :class="loader ? 'pa-4' : 'mb-0'" :loading="loader" type="text">
            <v-subheader>{{ $tc('models.report',2) }}</v-subheader>
          </v-skeleton-loader>
          <v-list-item-group v-model="selectedMenu">
            <v-skeleton-loader :class="loader ? 'mb-4 pa-4' : 'mb-0 pa-0'" :loading="loader" type="paragraph, paragraph, paragraph">
              <v-list-item v-for="(item, i) in menu" :key="i" :to="item.route" link :exact="item.exact" v-if="!item.permission || canAny(item.permission)">
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-skeleton-loader>
          </v-list-item-group>

          <v-skeleton-loader :class="loader ? 'pa-4' : 'mb-0'" :loading="loader" type="text">
            <v-subheader>{{ $tc('models.account',2) }}</v-subheader>
          </v-skeleton-loader>
          <v-skeleton-loader :class="loader ? 'mb-4 pa-4' : 'mb-0 pa-0'" :loading="loader" type="paragraph, paragraph, paragraph">
            <v-list-item-group v-model="selectedAccount">
              <v-list-item v-for="(item, k) in account"  :key="k" :to="item.route" link :exact="item.exact">
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-skeleton-loader>
        </v-list>
      </v-navigation-drawer>
      <v-app-bar color="white" elevate-on-scroll app>

        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
        <v-skeleton-loader :loading="loader" type="avatar">
          <v-toolbar-title class="d-flex align-center">
            <img src="@/assets/logo.png" alt="logo" height="36"/>
          </v-toolbar-title>
        </v-skeleton-loader>

<!--        <v-progress-linear-->
<!--            :active="true"-->
<!--            :indeterminate="true"-->
<!--            absolute-->
<!--            bottom-->
<!--            color="blue"-->
<!--        ></v-progress-linear>-->

        <v-spacer></v-spacer>

        <v-skeleton-loader :class="loader ? 'me-1' : 'me-0'" :loading="loader" type="avatar">
          <v-btn @click="settings" icon>
            <v-icon>mdi-cog</v-icon>
          </v-btn>
        </v-skeleton-loader>

        <v-skeleton-loader :loading="loader" type="avatar">
          <v-btn @click="signOut" icon >
            <v-icon>mdi-exit-to-app</v-icon>
          </v-btn>
        </v-skeleton-loader>

      </v-app-bar>
    </template>
    <v-main class="blue lighten-5">
      <alert-message/>
      <router-view ></router-view>
    </v-main>

  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AlertMessage from "@/components/modals/AlertMessage.vue";

import {dateFormat} from "@/Helpers/DateService";

export default {
  components: { AlertMessage },
  data: () => ({
    loader: true,
    drawer: null,
    selectedAccount: 0,
    selectedMenu: 0,
  }),
  computed: {
    menu() {
      return [
        { text: this.$t('messages.static.overview'), icon: 'mdi-view-dashboard', route: '/', exact: true },
        { text: this.$tc('models.appointment', 2), icon: 'mdi-calendar', route: '/appointments', exact: true, permission:'view-list-appointment'},
        { text: this.$tc('models.patient', 2), icon: 'mdi-account', route: '/patients' ,exact: true, permission: 'view-list-patient' },
        { text: this.$t('messages.static.waitingQueue'), icon: 'mdi-human-queue', route: '/appointments/queue', exact: true },
        { text: this.$tc('models.medicalDocument', 2), icon: 'mdi-pill', route: '/medical-documents' , exact: true, permission: 'view-list-medical-document' },
        /* { text: 'Message', icon: 'mdi-message-text', route: '/messages' }, */
        { text: this.$tc('models.payment', 2), icon: 'mdi-cash-100', route: '/payments' , exact:true, permission: 'view-list-payment' },
      ]
    },
    account() {
      return [
        { text: this.$t('messages.static.help'), icon: 'mdi-alert-circle', route: '/help' , exact: true },
        { text: this.$tc('models.setting', 2) , icon: 'mdi-cog', route: '/settings/personal/profile' , exact: false, permission:'view-setting' },
      ]
    },
    ...mapGetters({
      authUser: 'auth/getUser',
      isAuthenticated: "auth/isAuthenticated"
    }),
    isAuthLayout() {
      if ( this.$route.meta.layout === 'AuthLayout' ) { return true }
    }
  },
  watch:{
    authUser:{
      handler(val){
        if (val?.name)
        {
          this.loader = false;
        }
      },
      deep:true,
    }
  },
  methods:{
    ...mapActions({
      logout: 'auth/logout',
    }),
    async signOut(){
      await this.logout();
    },
    settings() {
      return this.$router.push({ name: 'settings-personal-profile' }).catch(()=>{});
    },
  }
}
</script>

<style lang="scss">

.v-application {

  font-family: 'Montserrat', sans-serif !important;

  .display-1, .display-2, .display-3, .display-4 {
    font-family: 'Montserrat', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

}

</style>
