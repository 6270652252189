import {httpClient} from "@/plugins/axios";

const API_ROUTE = ''

export default {
    async login(payload){
        return await httpClient.post(`${API_ROUTE}/login`,payload)
    },
    async  logout(){
        return await httpClient.post(`${API_ROUTE}/logout`)
    },
    async me(){
        return await httpClient.get(`${API_ROUTE}/profile`)
    },
    async myOffice(){
        return await httpClient.get(`${API_ROUTE}/my-office`)
    },
    async updateOffice(payload){
        return await httpClient.put(`${API_ROUTE}/my-office`,payload)
    },
    async updateProfile(payload){
        return await httpClient.put(`${API_ROUTE}/profile`,payload)
    },
    async updateLanguage(payload){
        return await httpClient.put(`${API_ROUTE}/profile/language`,payload)
    },
    async updatePassword(payload){
        return await httpClient.put(`${API_ROUTE}/profile/password`,payload)
    },
    async deleteImage(){
        return await httpClient.delete(`${API_ROUTE}/profile/image`)
    },
    async uploadImage(image){
        let form = new FormData;
        form.append('image',image,image.name)
        return await httpClient.post(`${API_ROUTE}/profile/image`,form,{
            headers:{
                "Content-Type":'multipart/form-data'
            }
        })
    },
    async sendForgotPasswordEmail(email){
        return await httpClient.post(`${API_ROUTE}/password/forgot`,{email})
    },
    async resetPassword(payload){
        return await httpClient.post(`${API_ROUTE}/password/reset`,payload)
    },

    async checkResetPasswordToken(token,email){
        return await httpClient.get(`${API_ROUTE}/password/reset/${token}/${email}/check`)
    },

    async updateDoctorMetadata(payload){
        return await httpClient.post(`${API_ROUTE}/profile/doctor/metadata`,payload,{
            headers:{
                "Content-Type":'multipart/form-data'
            }
        })
    }

}